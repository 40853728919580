import {
    uniqueNamesGenerator,
    adjectives,
    colors,
    animals
} from "unique-names-generator";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";
import { useModalStore } from "../store/modalStore";
import { useUserStore } from "../store/userStore";
import { useRuntimeConfig } from "nuxt/app";
import pkg from "../package.json";

export function generateUsername(): string {
    const options = {
        dictionaries: [adjectives, colors, animals],
        separator: "",
        length: 2
    };

    let uniqueName = uniqueNamesGenerator(options);
    let randomNumbers = Math.floor(Math.random() * 1000);

    while (uniqueName.length + randomNumbers.toString().length > 12) {
        uniqueName = uniqueName.slice(0, -1);
    }
    return uniqueName + randomNumbers;
}

export async function checkAppVersion(): Promise<void> {
    try {
        const mStore = useModalStore();
        const result = await AppUpdate.getAppUpdateInfo();
        if (Capacitor.getPlatform() === "ios") {
            if (
                typeof result.availableVersionName === "string" &&
                compareVersions(result.availableVersionName, pkg.version) > 0
            ) {
                mStore.toggleOutOfDateModal(true);
            }
        } else if (
            Capacitor.getPlatform() === "android" &&
            result.availableVersionCode
        ) {
            if (
                typeof result.availableVersionCode === "string" &&
                result.availableVersionCode > result.currentVersionCode
            ) {
                mStore.toggleOutOfDateModal(true);
            }
        }
    } catch (error) {
        console.error("Error checking for app update: ", error);
    } finally {
        AppUpdate.removeAllListeners();
    }
}

export function generateRandomGuestID(length: number = 12) {
    const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

function compareVersions(versionA: string, versionB: string) {
    const segmentsA = versionA.split(".").map(Number);
    const segmentsB = versionB.split(".").map(Number);

    for (let i = 0; i < Math.max(segmentsA.length, segmentsB.length); i++) {
        const diff = (segmentsA[i] || 0) - (segmentsB[i] || 0);
        if (diff !== 0) return diff;
    }
    return 0;
}

export async function refreshToken() {
    const uStore = useUserStore();
    try {
        if (uStore.loggedIn === false) {
            console.warn("User is not logged in.");
            return;
        }
        const config = useRuntimeConfig();

        const response = await $fetch(
            config.public.API_BASE_URL + "auth/refresh_token/",
            {
                method: "POST",
                body: { refresh: uStore.refreshToken }
            }
        );
        var expiryDate = new Date();
        expiryDate.setMinutes(
            expiryDate.getMinutes() + config.public.TOKEN_EXPIRY_TIME
        );
        uStore.expiryDate = expiryDate;
        uStore.setAccessToken(response.access);
        uStore.userService?.setAccessToken(response.access);
    } catch (error) {
        console.error(`User ${uStore.user?.username} session timed out.`);
        uStore.logout();
        throw error;
    }
}

export async function checkRefreshExpiry() {
    const uStore = useUserStore();
    const expiryDate = uStore.expiryDate;
    const now = new Date();

    if (!expiryDate) {
        console.warn("Expiry date is not set.");
        return;
    }
    if (isNaN(expiryDate.getTime())) {
        console.error("Expiry date is invalid.");
        return;
    }
    if (expiryDate.getTime() <= now.getTime()) {
        console.log("Refreshing token...");
        await refreshToken();
    }
}
