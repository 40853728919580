import { defineStore } from "pinia";
import { useNuxtApp, useRuntimeConfig } from "nuxt/app";
import { useUserStore } from "./userStore";
import { useFriendStore } from "./friendStore";
import { useModalStore } from "./modalStore";
import { useKeywordQuestionStore } from "./keywordQuestionStore.ts";
import type { StoreDefinition } from "pinia";
import type { NodeThemeType } from "../interfaces/nodes";
import type { GuestUserActivityType } from "../interfaces/user";
import { useNodeStore } from "./nodeStore";
import { getConnectedNodes } from "../utils/mockResp";
import { questions } from "../phaser/local_text/encryption.js" assert { type: "json" };
import { generateUsername, generateRandomGuestID } from "../utils/utils";
import { Device } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import pkg from "../package.json";

export interface State {
    guestID: string;
    signedUpID: number;
    signupPlatform: string;
    completedGuestNode: boolean;
    completedNodes: Record<number, NodeThemeType>[];
    seenSignupMessage: boolean;
    seenActionSignupModal: number;
    seenWelcomeModal: boolean;
    completedTutorial: boolean;
    encryptionMinigamesScores: {
        endlessRunner: number | null;
        memory: number | null;
        bulletHell: number | null;
    };
    encryptionMinigamesTutorialWatched: {
        endlessRunner: boolean;
        memory: boolean;
        bulletHell: boolean;
    };
    languageCode: string;
    questline: string;
    userSignupData: {
        email: string;
        password: string;
        first_name: string;
        last_name: string;
        username: string;
    };
    fcmToken: string;
    pkgVersion: string;
    showVersionUpdateModal: boolean;
}

export const useGuestStore: StoreDefinition<"guest", State> = defineStore(
    "guest",
    {
        state: (): State => ({
            guestID: "",
            signedUpID: 0,
            signupPlatform: "",
            completedGuestNode: false,
            completedNodes: [],
            seenSignupMessage: false,
            seenWelcomeModal: false,
            completedTutorial: false,
            encryptionMinigamesScores: {
                endlessRunner: null,
                memory: null,
                bulletHell: null
            },
            encryptionMinigamesTutorialWatched: {
                endlessRunner: false,
                memory: false,
                bulletHell: false
            },
            languageCode: "",
            questline: "",
            seenActionSignupModal: 0,
            userSignupData: {
                email: "",
                password: "",
                first_name: "",
                last_name: "",
                username: ""
            },
            fcmToken: "",
            pkgVersion: "",
            showVersionUpdateModal: false
        }),
        getters: {
            cleanedState: (state: State) => {
                return {
                    completedGuestNode: state.completedGuestNode,
                    completedNodes: state.completedNodes,
                    completedTutorial: state.completedTutorial,
                    encryptionMinigamesScores: state.encryptionMinigamesScores,
                    encryptionMinigamesTutorialWatched:
                        state.encryptionMinigamesTutorialWatched,
                    seenActionSignupModal: state.seenActionSignupModal,
                    seenSignupMessage: state.seenSignupMessage,
                    seenWelcomeModal: state.seenWelcomeModal,
                    languageCode: state.languageCode,
                    guestID: state.guestID,
                    signupPlatform: state.signupPlatform,
                    fcmToken: state.fcmToken,
                    pkgVersion: state.pkgVersion
                };
            }
        },
        actions: {
            setFcmToken(fcmToken: string) {
                this.fcmToken = fcmToken;
                this.saveCapTokens();
            },
            setGuestID() {
                this.guestID = generateRandomGuestID();
                this.saveCapTokens();
            },
            setPlatform() {
                this.signupPlatform = Capacitor.getPlatform();
                this.saveCapTokens();
            },
            setPkgVersion() {
                this.pkgVersion = pkg.version;
                this.saveCapTokens();
            },
            setLanguageCode(languageCode: string) {
                this.languageCode = languageCode;
                this.saveCapTokens();
            },
            setQuestline(questline: string) {
                this.questline = questline;
            },
            changeLanguage(langaugeCode: string) {
                useNuxtApp().vueApp.config.globalProperties.$i18n.setLocale(
                    langaugeCode
                );
                this.setLanguageCode(langaugeCode);
            },
            async getLanguage() {
                const deviceLangCode = await Device.getLanguageCode();
                if (!this.deviceLangInLocale(deviceLangCode.value)) {
                    this.changeLanguage("en");
                    return;
                }
                this.changeLanguage(deviceLangCode.value);
            },
            deviceLangInLocale(deviceLangCode: string) {
                return useNuxtApp().vueApp.config.globalProperties.$i18n.locales.some(
                    (locale) => locale.code === deviceLangCode
                );
            },
            togglecompletedGuestNode(bool: boolean) {
                this.completedGuestNode = bool;
                this.saveCapTokens();
            },
            toggleCompletedTutorial(bool: boolean) {
                this.completedTutorial = bool;
                this.saveCapTokens();
            },
            toggleWelcomeModal() {
                this.seenWelcomeModal = true;
                this.saveCapTokens();
            },
            incrementSeenActionSignupModal() {
                this.seenActionSignupModal++;
                this.saveCapTokens();
            },
            updateUserSignupData(data: Partial<State["userSignupData"]>) {
                this.userSignupData = { ...this.userSignupData, ...data };
            },
            updateEncryptionMinigamesScores(
                data: Partial<State["encryptionMinigamesScores"]>
            ) {
                this.encryptionMinigamesScores = {
                    ...this.encryptionMinigamesScores,
                    ...data
                };
                this.saveCapTokens();
            },
            updateEncryptionMinigamesTutorialWatched(
                data: Partial<State["encryptionMinigamesTutorialWatched"]>
            ) {
                this.encryptionMinigamesTutorialWatched = {
                    ...this.encryptionMinigamesTutorialWatched,
                    ...data
                };
                this.saveCapTokens();
            },
            clear() {
                this.completedGuestNode = false;
                this.completedNodes = [];
                this.seenSignupMessage = false;
                this.pkgVersion = pkg.version;
                this.questline = "";
                this.clearEncryptionMinigamesScores();
                this.clearEncryptionMinigamesTutorialWatched();
                this.clearUserSignupData();
                this.saveCapTokens();
            },
            clearUserSignupData() {
                this.userSignupData = {
                    email: "",
                    password: "",
                    first_name: "",
                    last_name: "",
                    username: ""
                };
                this.setQuestline("");
            },
            clearEncryptionMinigamesScores() {
                this.encryptionMinigamesScores = {
                    endlessRunner: null,
                    memory: null,
                    bulletHell: null
                };
            },
            clearEncryptionMinigamesTutorialWatched() {
                this.encryptionMinigamesTutorialWatched = {
                    endlessRunner: false,
                    memory: false,
                    bulletHell: false
                };
            },
            async handleRehydration(state) {
                this.completedGuestNode = state.completedGuestNode;
                this.completedNodes = state.completedNodes;
                this.seenSignupMessage = state.seenSignupMessage;
                this.completedTutorial = state.completedTutorial;
                this.encryptionMinigamesScores =
                    state.encryptionMinigamesScores;
                this.encryptionMinigamesTutorialWatched =
                    state.encryptionMinigamesTutorialWatched;
                this.seenWelcomeModal = state.seenWelcomeModal;
                this.languageCode = state.languageCode;
                this.guestID = state.guestID;
                this.signupPlatform = state.signupPlatform;
                this.pkgVersion = state.pkgVersion;
            },
            async initGuestUser() {
                this.setGuestID();
                this.setPlatform();
                this.setPkgVersion();
                this.getLanguage();
                this.clearEncryptionMinigamesScores();
                this.clearEncryptionMinigamesTutorialWatched();
            },
            updateCompletedNodes(nodeID: number) {
                // const node = latticeData.nodedata[nodeID];
                if (Number(nodeID) === -5) {
                    const mStore = useModalStore();
                    this.togglecompletedGuestNode(true);
                    mStore.toggleSignupActionModal(true);
                }
                // this.completedNodes.push(node);
                this.setMockLattice(nodeID);
            },
            async setMockLattice(completedNodeID = undefined) {
                const nStore = useNodeStore();
                const kqStore = useKeywordQuestionStore();
                try {
                    kqStore.setQuestions(questions);
                    const lattice = getConnectedNodes(this.completedNodes);
                    lattice.then((lattice) => {
                        if (completedNodeID) {
                            nStore.handleLatticeResponse(
                                lattice,
                                completedNodeID
                            );
                        } else {
                            nStore.handleLatticeResponse(lattice);
                        }
                    });
                } catch (error) {
                    console.error("Error fetching connected nodes:", error);
                }
            },
            async signupUser(toast: Boolean = true) {
                try {
                    this.updateUserSignupData({
                        username: generateUsername()
                    });
                    this.setPlatform();
                    await $fetch(
                        useRuntimeConfig().public.API_BASE_URL + "user/",
                        {
                            method: "POST",
                            body: {
                                ...this.userSignupData,
                                guestState: this.cleanedState
                            }
                        }
                    ).then((res: any) => {
                        if (toast) {
                            useNuxtApp().vueApp.config.globalProperties.$toast(
                                `<div style="text-align: center;">
                                    <h2>🎉 Welcome, <b>${res.username}</b>! 🎉</h2>
                                    <p>Your account is ready to go. Dive in and start exploring!</p>
                                </div>`,
                                7500
                            );
                        }
                        this.signedUpID = res.id; // Temporarily save the user ID so we can fetch the user later
                        if (Capacitor.isNativePlatform()) {
                            useNuxtApp().vueApp.config.globalProperties.$subscriptionService.updateApplicationUsername(
                                res.id
                            );
                        }
                        this.clearUserSignupData();
                        this.clearEncryptionMinigamesScores();
                        this.clearEncryptionMinigamesTutorialWatched();
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async signUserIn(credentials: {
                email: string;
                password: string;
            }): Promise<void> {
                try {
                    await $fetch(
                        useRuntimeConfig().public.API_BASE_URL + "auth/token/",
                        {
                            method: "POST",
                            body: {
                                email: credentials.email,
                                password: credentials.password,
                                guestState: this.cleanedState
                            }
                        }
                    ).then((res: any) => {
                        const uStore = useUserStore();
                        const fStore = useFriendStore();

                        uStore.handleLoginResponse(res);
                        if (Capacitor.isNativePlatform()) {
                            useNuxtApp().vueApp.config.globalProperties.$subscriptionService.updateApplicationUsername();
                        }
                        fStore.setFriends(res.friends);
                    });
                } catch (err: any) {
                    if (err.response && err.response.status === 401) {
                        throw new Error(
                            err.response._data.detail +
                                ". If you have recently registered, please check your email for a verification link to activate your account."
                        );
                    } else {
                        throw new Error("An error occurred. Please try again.");
                    }
                }
            },
            async signUserInFromID(subscribed: boolean = false): Promise<void> {
                // Fetch the user, and their data, from the server based on the signedUpID
                try {
                    await $fetch(
                        useRuntimeConfig().public.API_BASE_URL +
                            "auth/fetch-user-by-id/" +
                            this.signedUpID +
                            "/" +
                            this.guestID +
                            "/",
                        {
                            method: "GET"
                        }
                    ).then((res: any) => {
                        const uStore = useUserStore();
                        const fStore = useFriendStore();

                        uStore.handleLoginResponse(res);
                        if (subscribed) {
                            uStore.checkUserPostValidation();
                        }

                        if (Capacitor.isNativePlatform()) {
                            useNuxtApp().vueApp.config.globalProperties.$subscriptionService.updateApplicationUsername();
                        }
                        fStore.setFriends(res.friends);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async submitActivity(activity: string, context: string) {
                // activity list:
                //      node_opened
                //      node_completed
                //      signup_opened
                //      login_opened
                //      launch_pressed
                const body: GuestUserActivityType = {
                    activity: activity,
                    guest_user_id: this.guestID,
                    app_version: pkg.version,
                    device_type: Capacitor.getPlatform()
                };
                if (!useRuntimeConfig().public.enableLogging) {
                    return;
                }
                if (
                    context &&
                    typeof context === "string" &&
                    context.trim() !== ""
                ) {
                    body.context = context.trim();
                }
                try {
                    await $fetch(
                        useRuntimeConfig().public.API_BASE_URL +
                            "guestuseractivity/",
                        {
                            method: "POST",
                            body: body
                        }
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            async saveCapTokens(): Promise<void> {
                // Save tokens to Capacitor preferences
                try {
                    try {
                        await Preferences.set({
                            key: "gStore",
                            value: JSON.stringify(this.cleanedState)
                        });
                    } catch (err) {
                        console.error(
                            "Error saving guest store to Capacitor preferences:",
                            err
                        );
                        throw err;
                    }
                } catch (err) {
                    console.error(
                        "Error saving stores to Capacitor preferences:",
                        err
                    );
                    throw err;
                }
            }
        }
    }
);
