import type {
    UserInfoType,
    UserPersonalizationType,
    UserPrefType
} from "../interfaces/user";
import type { LatticeResponse } from "../interfaces/nodes";
import type { KeywordQuestionResponse } from "../interfaces/keywordsQuestions";
import { useRuntimeConfig } from "nuxt/app";
import { Preferences } from "@capacitor/preferences";
import { useUserStore } from "../store/userStore";
import { useNodeStore } from "../store/nodeStore";
import { useKeywordQuestionStore } from "../store/keywordQuestionStore";
import { useFetch } from "nuxt/app";

class UserService {
    private userId: number | null = null;
    private userPrefsId: number | null = null;
    private userPersonalizationId: number | null = null;
    private accessToken: string | null = null;
    private refreshToken: string | null = null;
    private apiBaseUrl: any;

    constructor(
        userId: number | null,
        userPrefsId: number | null,
        userPersonalizationId: number | null,
        accessToken: string | null,
        refreshToken: string | null
    ) {
        this.userId = userId;
        this.userPrefsId = userPrefsId;
        this.userPersonalizationId = userPersonalizationId;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.apiBaseUrl = useRuntimeConfig().public.API_BASE_URL;
    }

    public async setAccessToken(accessToken: string): Promise<void> {
        this.accessToken = accessToken;
    }

    async fetchUserDetailsAndPrefs(): Promise<{
        userDetails: UserInfoType;
        userPrefs: UserPrefType;
        userPersonalization: UserPersonalizationType;
    }> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}user/user_details_and_prefs/`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch user details and preferences");
            }
            return await response.json();
        } catch (error) {
            console.error(
                "Error fetching user details and preferences:",
                error
            );
            throw error;
        }
    }

    async savePrefsUser(prefs: UserPrefType): Promise<UserPrefType> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}userpref/${this.userPrefsId}/`,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(prefs)
                }
            );
            if (!response.ok) {
                throw new Error("Failed to update user preferences");
            }
            return await response.json();
        } catch (error) {
            console.error("Error updating user preferences:", error);
            throw error;
        }
    }

    async saveUserData(payload: { [key: string]: any }): Promise<UserInfoType> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}user/${this.userId}/`,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (err) {
            console.error("Error saving user data:", err);
            throw err;
        }
    }

    async purchaseItem(itemId: number): Promise<void> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}auth/user_personalization/${this.userPersonalizationId}/purchase_item/?item_id=${itemId}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }
            );

            if (!response.ok) {
                throw new Error("Failed to purchase item");
            }

            return await response.json();
        } catch (error) {
            console.error("Error purchasing item:", error);
            throw error;
        }
    }

    async saveUserPersonalizationData(payload: {
        [key: string]: any;
    }): Promise<any> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}auth/user_personalization/${this.userPersonalizationId}/`,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (err) {
            console.error("Error saving user personalization data:", err);
            throw err;
        }
    }

    async fetchAllItems(type: string): Promise<void> {
        // type: "ship" | "ect"
        try {
            const response = await fetch(
                `${this.apiBaseUrl}auth/item/?type=${type}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch all items");
            }
            return await response.json();
        } catch (err) {
            console.error("Error fetching all items:", err);
            throw err;
        }
    }

    async fetchQuestlineDetails(): Promise<void> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}completion/getQuestlineCompletion/`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch questline progress");
            }
            return await response.json();
        } catch (err) {
            console.error("Error fetching questline progress:", err);
            throw err;
        }
    }

    async fetchLattice(): Promise<LatticeResponse> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}user/getUserLattice/`,
                {
                    method: "GET",
                    headers: new Headers({
                        Authorization: `Bearer ${this.accessToken}`
                    })
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch all items");
            }
            return await response.json();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async fetchKeywordQuestions(): Promise<KeywordQuestionResponse> {
        try {
            const response = await fetch(
                `${this.apiBaseUrl}keyword/getKQStateList/`,
                {
                    method: "GET",
                    headers: new Headers({
                        Authorization: `Bearer ${this.accessToken}`
                    })
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch all items");
            }
            return await response.json();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async addNodeAction(nodeID: number): Promise<any> {
        try {
            const { data, error } = await useFetch(
                `${this.apiBaseUrl}completion/${this.userId}/addNode/${nodeID}/`,
                {
                    method: "PATCH",
                    headers: new Headers({
                        Authorization: `Bearer ${this.accessToken}`
                    })
                }
            );
            if (error.value) {
                throw new Error(error.value.message);
            }
            return await data.value;
        } catch (err) {
            console.log(err);
        }
    }

    async logout(): Promise<void> {
        try {
            const response = await fetch(`${this.apiBaseUrl}auth/logout/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ refresh: this.refreshToken })
            });
            if (!response.ok) {
                throw new Error("Logout failed");
            }
        } catch (err) {
            console.error("Logout failed:", err);
            throw err;
        }
    }

    async saveCapTokens(type = "all"): Promise<void> {
        // Save tokens to Capacitor preferences
        const uStore = useUserStore();
        const nStore = useNodeStore();
        const kqStore = useKeywordQuestionStore();
        try {
            try {
                if (type === "user" || type === "all") {
                    await Preferences.set({
                        key: "uStore",
                        value: JSON.stringify(uStore.cleanedState)
                    });
                }
                if (type === "nodes" || type === "all") {
                    await Preferences.set({
                        key: "nStore",
                        value: JSON.stringify(nStore.cleanedState)
                    });
                }
                if (type === "kq" || type === "all") {
                    await Preferences.set({
                        key: "kqStore",
                        value: JSON.stringify(kqStore.cleanedState)
                    });
                }
            } catch (err) {
                console.error(
                    "Error saving user store to Capacitor preferences:",
                    err
                );
                throw err;
            }
        } catch (err) {
            console.error("Error saving stores to Capacitor preferences:", err);
            throw err;
        }
    }

    async deleteCapTokens(): Promise<void> {
        try {
            await Preferences.remove({ key: "uStore" });
            await Preferences.remove({ key: "nStore" });
        } catch (err) {
            console.error("Error deleting Capacitor preferences:", err);
            throw err;
        }
    }
}

export default UserService;
