import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import { useNuxtApp, useRuntimeConfig, useRouter } from "nuxt/app";
import { Capacitor } from "@capacitor/core";
import { useModalStore } from "./modalStore";
import { useGuestStore } from "./guestStore";
import { useNodeStore } from "../store/nodeStore";
import { useFriendStore } from "./friendStore";
import { useKeywordQuestionStore } from "./keywordQuestionStore";
import UserService from "../services/UserService";
import type {
    UserSessionParamsType,
    UserInfoType,
    UserPrefType,
    UserPersonalizationType,
    QuestlineDetailsType
} from "../interfaces/user";

export interface State {
    user: UserInfoType | null;
    userPrefs: UserPrefType | null;
    userPersonalization: UserPersonalizationType | null;
    questlineDetails: QuestlineDetailsType | null;
    loggedIn: boolean;
    accessToken: string;
    refreshToken: string;
    expiryDate: Date;
    acceptedTerms: Boolean;
    currentStreak: number;
    updateUserIntervalId: number | null;
    userService: UserService | null;
    subscriptionCheckTimeout: NodeJS.Timeout | null;
}

export const useUserStore: StoreDefinition<"user", State> = defineStore(
    "user",
    {
        // @ts-ignore
        state: (): State => {
            return {
                user: null,
                userPrefs: null,
                userPersonalization: null,
                questlineDetails: null,
                loggedIn: false,
                accessToken: "",
                refreshToken: "",
                expiryDate: new Date(),
                acceptedTerms: false,
                currentStreak: 0,
                updateUserIntervalId: null,
                userService: null,
                subscriptionCheckTimeout: null
            };
        },
        getters: {
            cleanedState: (state: State) => {
                const {
                    user,
                    userPrefs,
                    userPersonalization,
                    questlineDetails,
                    loggedIn,
                    accessToken,
                    refreshToken,
                    expiryDate,
                    acceptedTerms,
                    currentStreak
                } = state;
                return {
                    user,
                    userPrefs,
                    userPersonalization,
                    questlineDetails,
                    loggedIn,
                    accessToken,
                    refreshToken,
                    expiryDate,
                    acceptedTerms,
                    currentStreak
                };
            },
            hasAccess(state: State) {
                if (!state.user) {
                    return false;
                }
                if (state.user.is_staff) {
                    return true;
                }
                return state.user.is_subscribed;
            }
        },
        actions: {
            updateUserDetailsAndPrefs(): Promise<void> {
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        if (this.userService) {
                            const {
                                userDetails,
                                userPrefs,
                                userPersonalization
                            } =
                                await this.userService.fetchUserDetailsAndPrefs();
                            this.setUser(userDetails);
                            this.setUserPrefs(userPrefs);
                            this.setUserPersonalization(userPersonalization);
                            if (this.userService) {
                                this.userService.saveCapTokens("user");
                            }
                        }
                        const fStore = useFriendStore();
                        await fStore.fetchLatestFriends();
                        this.loggedIn = true;
                        resolve();
                    } catch (error) {
                        console.error(
                            "Error fetching user details and preferences:",
                            error
                        );
                        this.logout();
                        reject(error);
                    }
                });
            },
            async startUpdateUserInterval() {
                // Update user details every 15 minutes
                if (this.updateUserIntervalId !== null) {
                    clearInterval(this.updateUserIntervalId);
                }
                this.updateUserIntervalId = window.setInterval(() => {
                    console.log("Updating user details...");
                    this.updateUserDetailsAndPrefs();
                }, 900000); // 900000
            },
            async updatePrefsUser(prefs: UserPrefType) {
                if (!this.user || !this.userService) {
                    console.error(
                        "User object is null, cannot update preferences."
                    );
                    return;
                }
                try {
                    const updatedUserPref =
                        await this.userService.savePrefsUser(prefs);
                    this.setUserPrefs(updatedUserPref);
                    this.userService.saveCapTokens("user");
                    return updatedUserPref;
                } catch (error) {
                    console.error("Error updating user preferences:", error);
                }
            },
            async updateUser(payload: { [key: string]: any }) {
                if (!this.user || !this.userService) {
                    console.error(
                        "UserService is not initialized. Cannot save user data."
                    );
                    return;
                }
                try {
                    const updatedUser =
                        await this.userService.saveUserData(payload);
                    this.setUser(updatedUser);
                    this.userService.saveCapTokens("user");
                    return updatedUser;
                } catch (error) {
                    console.error("Error saving user data:", error);
                    throw error;
                }
            },
            async updateUserPersonalization(payload: { [key: string]: any }) {
                if (!this.user || !this.userService) {
                    console.error(
                        "UserService is not initialized. Cannot save user personalization data."
                    );
                    return;
                }
                try {
                    const updatedPersonalizationData =
                        await this.userService.saveUserPersonalizationData(
                            payload
                        );
                    this.setUserPersonalization(updatedPersonalizationData);
                    this.userService.saveCapTokens("user");
                    return updatedPersonalizationData;
                } catch (error) {
                    console.error(
                        "Error saving user personalization data:",
                        error
                    );
                    throw error;
                }
            },
            async purchaseItem(item: number) {
                if (!this.user || !this.userService) {
                    console.error(
                        "UserService is not initialized. Cannot purchase item."
                    );
                    return;
                }
                try {
                    const updatedPersonalizationData =
                        await this.userService.purchaseItem(item);
                    this.setUserPersonalization(updatedPersonalizationData);
                    return updatedPersonalizationData;
                } catch (error) {
                    console.error("Error purchasing item:", error);
                    throw error;
                }
            },
            clearUpdateUserInterval() {
                if (this.updateUserIntervalId !== null) {
                    clearInterval(this.updateUserIntervalId);
                    this.updateUserIntervalId = null;
                }
            },
            login() {
                this.loggedIn = true;
                useModalStore().toggleNodeModal(false);
                this.startUpdateUserInterval();
                if (this.userService) {
                    this.userService.saveCapTokens();
                }
                this.setUserLanguage(this.user?.language || "en");
                // this.checkTerms();
                this.checkQuestline();

                const gStore = useGuestStore();
                if (gStore.fcmToken) {
                    this.updateUser({ fcm_token: gStore.fcmToken });
                }
            },
            async handleLoginResponse(res: any) {
                const config = useRuntimeConfig();
                const sessionParams: UserSessionParamsType = {
                    user: res.user,
                    accessToken: res.access || res.accessToken,
                    refreshToken: res.refresh || res.refreshToken,
                    expiryMinutes: parseInt(
                        String(config.public.TOKEN_EXPIRY_TIME)
                    ),
                    userPrefs: res.userPrefs,
                    userPersonalization: res.userPersonalization,
                    questlineDetails: res.questlineDetails,
                    acceptedTerms: res.acceptedTerms,
                    currentStreak: res.currentStreak
                };
                if (res.userLattice) {
                    const nStore = useNodeStore();
                    nStore.handleLatticeResponse(res.userLattice);
                }
                if (res.keywordQuestionList) {
                    const kqStore = useKeywordQuestionStore();
                    kqStore.setKQs(res.keywordQuestionList);
                }
                this.initializeUserSession(sessionParams);
            },
            initializeUserSession(params: UserSessionParamsType) {
                this.setUser(params.user);
                this.setAccessToken(params.accessToken);
                this.setRefreshToken(params.refreshToken);
                this.setExpiryDate(params.expiryMinutes);
                this.setUserPrefs(params.userPrefs);
                this.setUserPersonalization(params.userPersonalization);
                this.setQuestlineDetails(params.questlineDetails);
                this.setAcceptedTerms(params.acceptedTerms);
                this.setCurrentStreak(params.currentStreak);

                this.setupUserService();
                this.checkSubscription();
                this.login();
            },
            setUser(user: UserInfoType) {
                this.user = user;
            },
            setAccessToken(token: string) {
                this.accessToken = token;
            },
            setRefreshToken(token: string) {
                this.refreshToken = token;
            },
            setExpiryDate(expiryMinutes: number) {
                const expiryDate = new Date();
                const tokenExpiryMilliseconds = expiryMinutes * 60 * 1000; // Convert to milliseconds
                expiryDate.setTime(
                    expiryDate.getTime() + tokenExpiryMilliseconds
                );
                this.expiryDate = expiryDate;
            },
            setUserPrefs(prefs: UserPrefType) {
                this.userPrefs = prefs;
            },
            setUserPersonalization(
                userPersonalization: UserPersonalizationType
            ) {
                this.userPersonalization = userPersonalization;
            },
            setQuestlineDetails(details: QuestlineDetailsType) {
                this.questlineDetails = details;
            },
            setAcceptedTerms(accepted: Boolean) {
                this.acceptedTerms = accepted;
            },
            setCurrentStreak(currentStreak: number) {
                this.currentStreak = currentStreak;
            },
            setUserLanguage(languageCode: string) {
                //@ts-ignore
                useNuxtApp().vueApp.config.globalProperties.$i18n.setLocale(
                    languageCode
                );
            },
            async checkTerms() {
                if (this.acceptedTerms === false) {
                    useModalStore().toggleTermsModal(true);
                }
            },
            async checkSubscription() {
                // If the user exists and is not subscribed
                if (
                    this.user &&
                    !this.user.is_staff &&
                    !this.user.is_subscribed &&
                    !useModalStore().isSubscriptionModalOpen
                ) {
                    // If the user was created today and not through SSO, skip showing the modal
                    if (!this.user.sso && this.checkCreatedRecently()) {
                        return;
                    }
                    setTimeout(() => {
                        useModalStore().toggleSubscriptionModal(true);
                    }, 2500);
                }
            },
            checkCreatedRecently() {
                if (!this.user) {
                    console.error("User object is null. Cannot check date.");
                    return false;
                }

                const userDate = new Date(this.user.date_joined);
                const currentDate = new Date();

                const fifteenMinutesAgo = new Date(
                    currentDate.getTime() - 15 * 60 * 1000
                );

                return userDate >= fifteenMinutesAgo && userDate <= currentDate;
            },
            async checkQuestline() {
                if (!this.user?.currentquest) {
                    useModalStore().toggleQuestlineModal(true);
                }
            },
            async addNode(nodeID: number): Promise<void> {
                return new Promise<void>(async (resolve, reject) => {
                    if (this.userService) {
                        const nStore = useNodeStore();

                        // Step 1: Visually update the node to completed
                        const nodeStatus = nStore.getNodeStatus(nodeID);
                        if (nodeStatus !== "available") {
                            nStore.setNodeToCompleted(nodeID);

                            // Step 2: Add the unavailable nodes that are now available
                            if (nodeStatus !== "unavailable") {
                                nStore.addUnavailableNodesToAvailable(nodeID);
                            }
                        }

                        const data =
                            await this.userService.addNodeAction(nodeID);
                        if (data) {
                            const kqStore = useKeywordQuestionStore();
                            this.setUser(data.user);
                            this.setUserPersonalization(
                                data.userPersonalization
                            );
                            if (data.keywordQuestionList) {
                                kqStore.setKQs(data.keywordQuestionList);
                            }
                            if (data.userLattice) {
                                await nStore.handleLatticeResponse(
                                    data.userLattice,
                                    nodeID
                                );
                            }
                            this.userService.saveCapTokens();
                        }
                        resolve();
                    } else {
                        console.error(
                            "UserService is not initialized. Cannot add node."
                        );
                        reject();
                    }
                });
            },
            async updateQuestlineDetails() {
                if (!this.user || !this.userService) {
                    console.error(
                        "UserService is not initialized. Cannot save user personalization data."
                    );
                    return;
                }
                try {
                    const questlineDetails =
                        await this.userService?.fetchQuestlineDetails();
                    this.setQuestlineDetails(questlineDetails);
                    this.userService.saveCapTokens("user");
                    return questlineDetails;
                } catch (error) {
                    console.error("Error saving user data:", error);
                    throw error;
                }
            },
            async logout() {
                this.loggedIn = false;
                const router = useRouter();
                this.clearUpdateUserInterval();

                try {
                    if (this.userService) {
                        this.userService.deleteCapTokens();
                        this.userService.logout();
                    }
                } catch (error) {
                    console.log("Logout failed:", error);
                } finally {
                    document.body.classList.toggle("dark", false);
                    this.resetStores();
                    // Reset hydrate guest state
                    router.push({ name: "index" });
                    const gStore = useGuestStore();
                    gStore.setMockLattice();
                }
            },
            resetStores() {
                const nStore = useNodeStore();
                const fStore = useFriendStore();
                const kqStore = useKeywordQuestionStore();

                this.$reset();
                nStore.$reset();
                fStore.$reset();
                kqStore.$reset();
            },
            setupUserService() {
                if (
                    this.accessToken &&
                    this.refreshToken &&
                    this.user &&
                    this.userPrefs &&
                    this.userPersonalization
                ) {
                    this.userService = new UserService(
                        this.user.id,
                        this.userPrefs?.id,
                        this.userPersonalization?.id,
                        this.accessToken,
                        this.refreshToken
                    );
                    if (
                        this.user.is_subscribed &&
                        this.user.subscription_expiry &&
                        this.user.subscription_expiry < new Date()
                    ) {
                        this.startSubscriptionCheck();
                    }
                }
            },
            startSubscriptionCheck() {
                if (this.subscriptionCheckTimeout) {
                    console.log("Clearing existing subscription check timeout");
                    clearTimeout(this.subscriptionCheckTimeout);
                    this.subscriptionCheckTimeout = null;
                }

                if (!this.user?.subscription_expiry) {
                    console.log("User subscription expiry date not set");
                    return;
                }

                const subscriptionExpiry = new Date(
                    this.user.subscription_expiry
                );
                const now = new Date();
                const buffer = 3 * 60 * 1000; // 3 minutes buffer
                const timeUntilExpiry =
                    subscriptionExpiry.getTime() - now.getTime() + buffer;
                if (timeUntilExpiry > buffer) {
                    // Calculate the time when the check will happen
                    const checkTime = new Date(now.getTime() + timeUntilExpiry);
                    console.log("Subscription check scheduled for:", checkTime);

                    // Set a timeout to check the subscription status right after it expires, plus the buffer
                    this.subscriptionCheckTimeout = setTimeout(
                        this.checkSubscriptionStatus,
                        timeUntilExpiry
                    );
                } else {
                    this.checkSubscriptionStatus();
                }
            },
            async checkSubscriptionStatus() {
                try {
                    await this.updateUserDetailsAndPrefs();
                    if (this.user && !this.user.is_subscribed) {
                        if (Capacitor.getPlatform() === "web") {
                            console.log(
                                "User subscription expired and is on web"
                            );
                            return;
                        } else {
                            console.log(
                                "User subscription expired and is on mobile"
                            );
                            return;
                        }
                    } else {
                        this.startSubscriptionCheck();
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async checkUserPostValidation(): Promise<() => void> {
                const maxRetries = 10;
                const intervalDuration = 7500;
                let retryCount = 0;
                let timeoutId: NodeJS.Timeout | null = null;

                if (!this.user || this.user.is_subscribed) return () => {};

                this.user.is_subscribed = true;

                const cancelTimeout = () => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        timeoutId = null;
                    }
                };

                const checkSubscription = async () => {
                    if (!this.userService || !this.user) return;

                    try {
                        const { userDetails } =
                            await this.userService.fetchUserDetailsAndPrefs();
                        console.log("Subscription check....");

                        if (
                            userDetails.is_subscribed ||
                            retryCount >= maxRetries - 1
                        ) {
                            this.setUser(userDetails);
                            this.startSubscriptionCheck();
                            cancelTimeout();
                        } else if (retryCount++ < maxRetries) {
                            cancelTimeout();
                            timeoutId = setTimeout(
                                checkSubscription,
                                intervalDuration
                            );
                        }
                    } catch (error) {
                        console.error("Error fetching user details:", error);

                        if (retryCount++ >= maxRetries) {
                            console.log(
                                "Max retries reached. Unable to verify subscription."
                            );
                            cancelTimeout();
                        } else {
                            cancelTimeout();
                            timeoutId = setTimeout(
                                checkSubscription,
                                intervalDuration
                            );
                        }
                    }
                };

                cancelTimeout();
                timeoutId = setTimeout(checkSubscription, intervalDuration);

                return cancelTimeout;
            }
        }
    }
);
