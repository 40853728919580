import { useUserStore } from "../store/userStore";
import { Capacitor } from "@capacitor/core";
import "cordova-plugin-purchase";

export class SubscriptionService {
    private config: any;
    private platform: string;
    private store: CdvPurchase.Store;
    private productList: any[];
    private cdvPlatform: CdvPurchase.Platform;
    private productListInitialized: boolean;

    constructor(config: any) {
        this.config = config;
        this.platform = Capacitor.getPlatform();
        this.productList = [];
        this.cdvPlatform =
            this.platform === "android"
                ? CdvPurchase.Platform.GOOGLE_PLAY
                : this.platform === "ios"
                  ? CdvPurchase.Platform.APPLE_APPSTORE
                  : CdvPurchase.Platform.TEST;
        this.productListInitialized = false;
    }

    async initializeCordovaStore(): Promise<boolean> {
        if (window.CdvPurchase && window.CdvPurchase.store) {
            this.store = window.CdvPurchase.store;
            await this.registerProducts();
            this.setupEventHandlers();
            this.updateApplicationUsername();
            const iaptic = new CdvPurchase.Iaptic({
                appName: "com.cloudcompany.arkitect",
                apiKey: "d30eec14-7d79-44fd-af30-a3923349e991"
            });
            this.store.validator = iaptic.validator;
            this.store.initialize();
            return true;
        } else {
            console.error("CdvPurchase not available");
            return false;
        }
    }

    updateApplicationUsername(userID = null) {
        const idToUse = userID || useUserStore().user?.id;

        if (!idToUse) {
            console.error("User ID not available");
            return;
        }
        this.store.applicationUsername = String(idToUse).padStart(10, "0");
        console.log(`Application username updated with ID: ${idToUse}`);
    }

    async registerProducts() {
        try {
            const response = await this.fetchSubscriptions();
            if (
                !response.subscriptions ||
                !Array.isArray(response.subscriptions)
            ) {
                throw new Error("Invalid subscription data format");
            }
            await Promise.all(
                response.subscriptions.map(async (subscription) => {
                    // console.log("subscription", JSON.stringify(subscription));
                    await this.store.register({
                        id: String(subscription.id),
                        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                        platform: this.cdvPlatform
                    });
                })
            );
        } catch (error) {
            console.error("Error registering products:", error);
        } finally {
            console.log("all products registered");
        }
    }

    async subscribeToProduct(productId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                let productToOrder = this.store.get(productId);
                if (
                    productToOrder !== null &&
                    productToOrder?.getOffer() !== null
                ) {
                    //@ts-ignore
                    productToOrder
                        .getOffer()
                        .order()
                        .then(
                            () => {
                                console.log("Subscription successful");
                                resolve();
                            },
                            (e) => {
                                console.error("purchase error", e);
                                reject(e);
                            }
                        );
                } else {
                    console.error(
                        "no product found, available products",
                        this.store.products
                    );
                    reject(new Error("Product not found"));
                }
            } catch (error) {
                console.error("Error subscribing to product:", error);
                reject(error);
            }
        });
    }

    async fetchSubscriptions() {
        const response = await fetch(
            `${this.config.public.API_BASE_URL}auth/subscription`
        );
        const jsonResponse = await response.json();
        const platformSubscription =
            this.platform === "android"
                ? jsonResponse.android
                : jsonResponse.ios;

        return {
            subscriptions: platformSubscription
        };
    }

    async setupEventHandlers() {
        let productsUpdated = 0;

        const extractOfferDetails = (
            offers: any[],
            paymentMode: string
        ): any | null => {
            const offer = offers.find((o) =>
                o.pricingPhases.some((p) => p.paymentMode === paymentMode)
            );
            return offer
                ? offer.pricingPhases.find((p) => p.paymentMode === paymentMode)
                : null;
        };

        const getDurationInfo = (
            billingPeriod: string
        ): { duration: number | null; durationUnit: string | null } => {
            if (!billingPeriod.startsWith("P"))
                return { duration: null, durationUnit: null };

            const duration = parseInt(billingPeriod.slice(1));
            const unit = billingPeriod[2] === "D" ? "day(s)" : "month(s)";

            return { duration, durationUnit: unit };
        };

        this.store
            .when()
            .productUpdated((product) => {
                this.store.update();

                const freeTrialPhase = extractOfferDetails(
                    product.offers,
                    "FreeTrial"
                );
                const payAsYouGoPhase = extractOfferDetails(
                    product.offers,
                    "PayAsYouGo"
                );

                const productData = {
                    id: product.id,
                    title: product.title,
                    description: product.description,
                    freeTrialDetails: freeTrialPhase
                        ? getDurationInfo(freeTrialPhase.billingPeriod)
                        : null,
                    pricingDetails: payAsYouGoPhase
                        ? {
                              price: payAsYouGoPhase.price,
                              currency: payAsYouGoPhase.currency,
                              ...getDurationInfo(payAsYouGoPhase.billingPeriod)
                          }
                        : null
                };

                this.productList.push(productData);
                productsUpdated++;

                if (productsUpdated === this.productList.length) {
                    this.productListInitialized = true;
                }
            })
            .approved((transaction) => transaction.verify())
            .verified((receipt) => {
                receipt.finish();
            })
            .unverified((receipt) => console.log("receipt cannot be verified"));
    }

    async waitForProductListInitialization(): Promise<void> {
        return new Promise(async (resolve) => {
            if (this.productListInitialized) {
                resolve();
            } else {
                const checkInterval = setInterval(async () => {
                    if (this.productListInitialized) {
                        clearInterval(checkInterval);
                        resolve();
                    }
                }, 150);
            }
        });
    }
}
