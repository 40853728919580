import { ref } from "vue";
import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import type {
    NodeThemeType,
    EdgeThemeType,
    NodeContentType,
    LayoutType,
    LatticeResponse
} from "../interfaces/nodes.ts";
import { NODE_THEMES, EDGE_THEMES } from "../interfaces/nodes.ts";
import { useUserStore } from "./userStore";

let defaultPlanetImage: any;
const planetImages = import.meta.glob("../assets/planets/*.png"); // TODO: Testing planet images

interface State {
    availableNodes: Record<number, NodeThemeType>;
    availableEdges: Record<`edge${string}-${string}`, EdgeThemeType>;
    availableNodeContent: Record<number, NodeContentType>;
    unavailableNodes: Record<number, NodeThemeType>; // N+2 nodes
    unavailableEdges: Record<`edge${string}-${string}`, EdgeThemeType>; // N+2 edges
    layouts: LayoutType;
    planetImagePaths: Record<number, string>;
    selectedNodeID: number;
    latticeFetchInProgress: boolean;
}

export const useNodeStore: StoreDefinition<"nodes", State> = defineStore(
    "nodes",
    {
        state: (): State => {
            return {
                availableNodes: {},
                availableEdges: {},
                availableNodeContent: {},
                unavailableNodes: {},
                unavailableEdges: {},
                layouts: { nodes: {} },
                planetImagePaths: {},
                selectedNodeID: 0,
                latticeFetchInProgress: false
            };
        },
        getters: {
            // Getter to retrieve the user's available nodes
            getAvailableNodes(): NodeThemeType[] {
                return Object.values(this.availableNodes);
            },
            getAvailableNodeCount(): number {
                return Object.keys(this.availableNodes).length;
            },
            getAvailableEdges(): EdgeThemeType[] {
                return Object.values(this.availableEdges);
            },
            getAvailableNodeContent(): NodeContentType[] {
                return Object.values(this.availableNodeContent);
            },
            cleanedState: (state: State) => {
                const {
                    availableNodes,
                    availableEdges,
                    availableNodeContent,
                    unavailableNodes,
                    unavailableEdges,
                    layouts,
                    planetImagePaths
                } = state;
                return {
                    availableNodes,
                    availableEdges,
                    availableNodeContent,
                    unavailableNodes,
                    unavailableEdges,
                    layouts,
                    planetImagePaths
                };
            }
        },
        actions: {
            // Action to update the user's available nodes
            updateNodes(newNodes: Record<number, NodeThemeType>) {
                this.availableNodes = newNodes;
                this.fixIntroNodePosition();
                this.fixGreatLibraryNodePosition();
                this.initializePlanetImages();
            },
            updateSpecificNode(nodeId: number, newNode: NodeThemeType) {
                this.availableNodes[nodeId] = newNode;
            },
            updateEdges(
                newEdges: Record<`edge${string}-${string}`, EdgeThemeType>
            ) {
                this.availableEdges = newEdges;
            },
            updateNodeContent(newNodeContent: Record<number, NodeContentType>) {
                this.availableNodeContent = newNodeContent;
            },
            updateSelectedNodeID(nodeID: number) {
                this.selectedNodeID = nodeID;
                const uStore = useUserStore();
                if (nodeID > 0) {
                    uStore.updateUser({ currentnode: this.selectedNodeID });
                }
            },
            updatelayouts(newlayouts) {
                const cleanedNodes = Object.fromEntries(
                    Object.entries(newlayouts.nodes).filter(
                        ([key, value]) => value !== null
                    )
                );
                newlayouts.nodes = cleanedNodes;
                this.fixIntroNodePosition();
                this.fixGreatLibraryNodePosition();
                this.layouts = newlayouts;
            },
            setNodeToCompleted(nodeID: number) {
                if (this.availableNodes[nodeID]) {
                    this.availableNodes[nodeID] = {
                        ...this.availableNodes[nodeID],
                        ...NODE_THEMES.COMPLETED
                    };
                    this.updateEdgesForCompletedNode(nodeID);
                } else {
                    console.error(
                        `Node with ID ${nodeID} does not exist in availableNodes.`
                    );
                }
            },
            updateEdgesForCompletedNode(nodeID: number) {
                Object.keys(this.availableEdges).forEach((edgeKey) => {
                    const edge = this.availableEdges[edgeKey];
                    if (edge.target == nodeID) {
                        this.availableEdges[edgeKey] = {
                            ...this.availableEdges[edgeKey],
                            ...EDGE_THEMES.COMPLETED
                        };
                    }
                });
            },
            addUnavailableNodesToAvailable(nodeID: number) {
                let nodesToAdd = {};
                let edgesToAdd = {};

                // Find nodes to add
                Object.keys(this.unavailableEdges).forEach((edgeKey) => {
                    const edge = this.unavailableEdges[edgeKey];
                    if (edge.source == nodeID) {
                        const targetNodeID = edge.target;
                        nodesToAdd[targetNodeID] =
                            this.unavailableNodes[targetNodeID];
                    }
                });

                // Find associative edges to add
                Object.keys(this.unavailableEdges).forEach((edgeKey) => {
                    const edge = this.unavailableEdges[edgeKey];
                    if (edge.source == nodeID) {
                        edgesToAdd[edgeKey] = edge;
                    }
                });

                // Add nodes to available and remove from unavailable
                for (const nodeId in nodesToAdd) {
                    this.availableNodes[nodeId] = nodesToAdd[nodeId];
                    this.availableNodes[nodeId] = {
                        ...this.availableNodes[nodeId],
                        ...NODE_THEMES.QUEST
                    };
                    delete this.unavailableNodes[nodeId];
                }

                // Add edges to available and remove from unavailable
                for (const edgeId in edgesToAdd) {
                    this.availableEdges[edgeId] = edgesToAdd[edgeId];
                    this.availableEdges[edgeId] = {
                        ...this.availableEdges[edgeId],
                        ...EDGE_THEMES.QUEST
                    };
                    delete this.unavailableEdges[edgeId];
                }

                // Update the layouts
                this.fixIntroNodePosition();
                this.fixGreatLibraryNodePosition();
                this.initializePlanetImages();
            },
            async handleRehydration(state) {
                this.updateNodes(state.availableNodes);
                this.availableEdges = state.availableEdges;
                this.availableNodeContent = state.availableNodeContent;
                this.unavailableNodes = state.unavailableNodes;
                this.unavailableEdges = state.unavailableEdges;
                this.updatelayouts(state.layouts);

                if (
                    !state.planetImagePaths ||
                    Object.keys(state.planetImagePaths).length === 0
                ) {
                    await this.initializePlanetImages();
                } else {
                    this.planetImagePaths = state.planetImagePaths;
                }
            },
            fixIntroNodePosition() {
                if ("-5" in this.availableNodes) {
                    this.layouts.nodes["-5"] = {
                        x: 0,
                        y: -350,
                        fixed: true
                    };
                    return;
                }
                for (const nodeId in this.availableNodes) {
                    const node = this.availableNodes[nodeId];
                    if (node.name.toString() === "Intro") {
                        this.layouts.nodes[nodeId] = {
                            x: 0,
                            y: 0,
                            fixed: true
                        };
                        break;
                    }
                }
            },
            fixGreatLibraryNodePosition() {
                this.layouts.nodes[-1] = {
                    x: 70,
                    y: 70,
                    fixed: true
                };
            },
            async getLatticeData(completedNodeId: number = 0) {
                return new Promise((resolve, reject) => {
                    this.latticeFetchInProgress = true;
                    const uStore = useUserStore();

                    uStore.userService
                        ?.fetchLattice()
                        .then((response: LatticeResponse) => {
                            this.handleLatticeResponse(
                                response,
                                completedNodeId
                            )
                                .then(() => {
                                    resolve();
                                })
                                .catch((error: Error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            this.latticeFetchInProgress = false;
                            reject(error);
                        })
                        .finally(() => {
                            this.latticeFetchInProgress = false;
                        });
                });
            },
            async handleLatticeResponse(
                response: LatticeResponse,
                completedNodeId: number = 0
            ) {
                return new Promise((resolve, reject) => {
                    try {
                        this.processLatticeData(response, completedNodeId);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            },
            async processLatticeData(
                response: LatticeResponse,
                completedNodeId: number = 0
            ) {
                const uStore = useUserStore();
                this.unavailableNodes = response.unavailable_nodedata;
                this.unavailableEdges = response.unavailable_edgedata;

                if (completedNodeId !== 0) {
                    this.updateSpecificNode(
                        completedNodeId,
                        response.nodedata[completedNodeId]
                    );
                }
                this.updateNodeContent(response.nodecontent);
                try {
                    this.updateNodes(response.nodedata);
                    this.updateEdges(response.edgedata);
                    if (uStore.loggedIn) {
                        uStore.userService?.saveCapTokens("nodes");
                    }
                    return;
                } catch (error) {
                    this.updateNodes(response.nodedata);
                    this.updateEdges(response.edgedata);
                    console.error(
                        "Overriding nStore. Error processing lattice data:",
                        error
                    );
                }
                return;
            },
            async loadDefaultPlanetImage() {
                try {
                    const defaultImageModule = await import(
                        "../assets/planets/Default.png"
                    );
                    defaultPlanetImage =
                        defaultImageModule.default || defaultImageModule;
                } catch (error) {
                    console.error(
                        "Failed to load default planet image:",
                        error
                    );
                }
            },
            async fetchPlanet(nodeId: number): Promise<string> {
                const planetName: string | undefined =
                    this.availableNodes[nodeId]?.name?.toString();
                if (!planetName) {
                    return defaultPlanetImage;
                }
                const planetPath = `../assets/planets/${planetName}.png`; // TODO: Testing planet images
                if (planetImages[planetPath]) {
                    try {
                        const planetImage = await planetImages[planetPath]();
                        return planetImage.default || planetImage;
                    } catch (error) {
                        console.warn(
                            `Planet image not found for ${planetName}, using default.`,
                            error
                        );
                        return defaultPlanetImage;
                    }
                } else {
                    console.warn(
                        `Planet image not found for ${planetName}, using default.`
                    );
                    return defaultPlanetImage;
                }
            },
            async initializePlanetImages() {
                const nodeIds = Object.keys(this.availableNodes);
                await this.loadDefaultPlanetImage();
                for (const nodeId of nodeIds) {
                    if (!this.planetImagePaths[nodeId]) {
                        this.planetImagePaths[nodeId] =
                            await this.fetchPlanet(nodeId);
                    }
                }
                const uStore = useUserStore();
                if (uStore.loggedIn) {
                    uStore.userService?.saveCapTokens("nodes");
                }
            },
            getNodeStatus(nodeID: number | string): string {
                const intNodeID = parseInt(nodeID.toString(), 10);

                if (!this.availableNodes && !this.unavailableNodes) {
                    return "unavailable";
                }

                const nodeStatus =
                    this.availableNodes?.[intNodeID]?.status ??
                    this.unavailableNodes?.[intNodeID]?.status;

                return typeof nodeStatus === "string"
                    ? nodeStatus
                    : "available";
            }
        }
    }
);
