import validate from "/workspace/src/nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/workspace/src/nuxt/middleware/auth.global.ts";
import theme_45global from "/workspace/src/nuxt/middleware/theme.global.ts";
import manifest_45route_45rule from "/workspace/src/nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  theme_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}