import type { LatticeResponse, NodeThemeType } from "../interfaces/nodes";
import { NODE_THEMES, EDGE_THEMES } from "../interfaces/nodes.ts";
import { scene_text } from "../phaser/local_text/encryption.js" assert { type: "json" };
/*
Updating the guestnode:
- Add scene_text and questions to phaser/local_text/{nodename}.js
- Update scene_text import above
- Update name and scene_folder below
- Head to guestStore and update the questions import
*/

const introLattice: Record<number, NodeThemeType> = {
    [-5]: {
        name: "Encryption",
        ...NODE_THEMES.GUEST
    }
};

export const latticeData: LatticeResponse = {
    nodedata: {
        [-5]: {
            name: "Encryption",
            ...NODE_THEMES.GUEST
        }
    },
    edgedata: {},
    nodecontent: {
        [-5]: {
            scene_folder: "encryption",
            questlines: [],
            node_scene_text: JSON.parse(JSON.stringify(scene_text))
        }
    },
    unavailable_nodedata: {},
    unavailable_edgedata: {}
};

export function getConnectedNodes(
    completedNodes: NodeThemeType[]
): Promise<LatticeResponse> {
    return new Promise<LatticeResponse>((resolve) => {
        const completedNodeIds = completedNodes.map((node) =>
            Object.keys(latticeData.nodedata).find(
                (key) => latticeData.nodedata[Number(key)].name === node.name
            )
        );

        if (completedNodeIds.length === 0) {
            resolve({
                nodedata: introLattice,
                edgedata: latticeData.edgedata,
                nodecontent: latticeData.nodecontent,
                unavailable_nodedata: {},
                unavailable_edgedata: {}
            });
        } else {
            const nextNodes: string[] = [];
            const previousNodes: string[] = [];

            for (const edgeKey in latticeData.edgedata) {
                const edge =
                    latticeData.edgedata[edgeKey as `edge${string}-${string}`];
                if (completedNodeIds.includes(edge.source)) {
                    nextNodes.push(edge.target);
                } else if (completedNodeIds.includes(edge.target)) {
                    previousNodes.push(edge.source);
                }
            }

            const uniqueNextNodes = [...new Set(nextNodes)];
            const uniquePreviousNodes = [...new Set(previousNodes)];

            const allConnectedNodeIds = [
                ...completedNodeIds,
                ...uniqueNextNodes,
                ...uniquePreviousNodes
            ];

            const filteredNodeData = Object.fromEntries(
                Object.entries(latticeData.nodedata)
                    .filter(([nodeId]) => allConnectedNodeIds.includes(nodeId))
                    .map(([nodeId, node]) => {
                        if (completedNodeIds.includes(nodeId)) {
                            return [
                                nodeId,
                                {
                                    ...node,
                                    ...NODE_THEMES.COMPLETED
                                }
                            ];
                        }
                        return [nodeId, node];
                    })
            );

            const filteredEdgeData = Object.fromEntries(
                Object.entries(latticeData.edgedata).map(([edgeKey, edge]) => {
                    if (
                        completedNodeIds.includes(edge.source) &&
                        completedNodeIds.includes(edge.target)
                    ) {
                        const updatedEdge = {
                            ...edge,
                            ...EDGE_THEMES.COMPLETED
                        };
                        return [edgeKey, updatedEdge];
                    }
                    return [edgeKey, edge];
                })
            );

            resolve({
                nodedata: filteredNodeData,
                edgedata: filteredEdgeData,
                nodecontent: latticeData.nodecontent,
                unavailable_nodedata: {},
                unavailable_edgedata: {}
            });
        }
    });
}
