<template>
  <NuxtLayout>
    <VitePwaManifest />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { watch, onMounted } from 'vue'

const { locale, localeProperties } = useI18n()

watch(locale, (newLocale) => {
  document.documentElement.dir = localeProperties.value.dir
  document.documentElement.lang = newLocale
})

onMounted(() => {
  document.documentElement.dir = localeProperties.value.dir
  document.documentElement.lang = locale.value
})
</script>