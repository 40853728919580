import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import { checkRefreshExpiry } from "../utils/utils";
import { useGuestStore } from "./guestStore";

export interface State {
    isUserPersModalOpen: boolean;
    isFriendsModalOpen: boolean;
    isQuestlineModalOpen: boolean; // QuestlineModal.vue
    isFeedbackModalOpen: boolean;
    isPhaserFeedbackModalOpen: boolean;
    isTermsModalOpen: boolean;
    isSsoModalOpen: boolean;
    isWelcomeModalOpen: boolean;
    isNodeModalOpen: boolean;
    isLoginModalOpen: boolean;
    isSignupModalOpen: boolean;
    isSignupActionModalOpen: boolean;
    isPasswordResetModalOpen: boolean;
    isOutOfDateModalOpen: boolean;
    isPatchNotesModalOpen: boolean;
    isSubscriptionModalOpen: boolean;
    isNodeOpen: boolean; // Phaser Instance
}

export const useModalStore: StoreDefinition<"modals", State> = defineStore(
    "modals",
    {
        state: (): State => ({
            isUserPersModalOpen: false,
            isFriendsModalOpen: false,
            isTermsModalOpen: false,
            isSsoModalOpen: false,
            isQuestlineModalOpen: false,
            isFeedbackModalOpen: false,
            isPhaserFeedbackModalOpen: false,
            isWelcomeModalOpen: false,
            isNodeModalOpen: false,
            isLoginModalOpen: false,
            isSignupModalOpen: false,
            isSignupActionModalOpen: false,
            isPasswordResetModalOpen: false,
            isOutOfDateModalOpen: false,
            isPatchNotesModalOpen: false,
            isSubscriptionModalOpen: false,
            isNodeOpen: false
        }),
        getters: {
            cleanedState: (state: State) => {
                return { state };
            }
        },
        actions: {
            toggleLoginModal(bool: boolean) {
                if (bool) {
                    this.isSignupModalOpen = false;
                    this.isPasswordResetModalOpen = false;
                }
                this.isLoginModalOpen = bool;
            },
            toggleSignupModal(bool: boolean) {
                if (bool) {
                    this.isLoginModalOpen = false;
                    this.isPasswordResetModalOpen = false;
                }
                this.isSignupModalOpen = bool;
            },
            togglePasswordResetModal(bool: boolean) {
                if (bool) {
                    this.isLoginModalOpen = false;
                    this.isSignupModalOpen = false;
                }
                this.isPasswordResetModalOpen = bool;
            },
            toggleSignupActionModal(bool: boolean) {
                if (bool) {
                    setTimeout(() => {
                        this.isSignupActionModalOpen = bool;
                    }, 2000);
                }
                if (!bool) {
                    const gStore = useGuestStore();
                    gStore.incrementSeenActionSignupModal();
                    this.isSignupActionModalOpen = bool;
                }
            },
            toggleTermsModal(bool: boolean) {
                this.isTermsModalOpen = bool;
            },
            toggleSsoModal(bool: boolean) {
                this.isSsoModalOpen = bool;
            },
            toggleUserPersModal() {
                this.isUserPersModalOpen = !this.isUserPersModalOpen;
            },
            toggleFriendsModal() {
                this.isFriendsModalOpen = !this.isFriendsModalOpen;
            },
            toggleQuestlineModal(bool: boolean) {
                this.isQuestlineModalOpen = bool;
            },
            toggleFeedbackModal() {
                this.isFeedbackModalOpen = !this.isFeedbackModalOpen;
            },
            togglePhaserFeedbackModal() {
                this.isPhaserFeedbackModalOpen =
                    !this.isPhaserFeedbackModalOpen;
            },
            toggleNodeModal(bool?: boolean) {
                this.isNodeModalOpen =
                    typeof bool === "boolean" ? bool : !this.isNodeModalOpen;
            },
            toggleOutOfDateModal(bool: boolean) {
                this.isOutOfDateModalOpen = bool;
            },
            togglePatchNotesModal(bool: boolean) {
                this.isPatchNotesModalOpen = bool;
            },
            toggleSubscriptionModal(bool: boolean) {
                this.isSubscriptionModalOpen = bool;
            },
            async toggleWelcomeModal(bool: boolean) {
                this.isWelcomeModalOpen = bool;
                const gStore = useGuestStore();
                gStore.toggleWelcomeModal(bool);
            },
            toggleNode(bool: boolean) {
                if (bool) {
                    checkRefreshExpiry();
                }
                this.isNodeOpen = bool;
            }
        }
    }
);
