import { useUserStore } from "../store/userStore";
import { defineNuxtRouteMiddleware } from "nuxt/app";

export default defineNuxtRouteMiddleware((to) => {
    const uStore = useUserStore();

    if (uStore.loggedIn && uStore.userPrefs?.dark_mode) {
        document.body.classList.toggle("dark", true);
    } else {
        document.body.classList.toggle("dark", false);
    }
});
