import { defineNuxtPlugin } from "nuxt/app";
import { toastController } from "@ionic/vue";

export default defineNuxtPlugin((nuxtApp) => {
    const presentToast = (message: string, duration = 5000) => {
        toastController
            .create({
                message: message,
                duration: duration,
                position: "top",
                cssClass: "custom-toast",
            })
            .then((toast) => toast.present());
    };
    nuxtApp.vueApp.config.globalProperties.$toast = presentToast;
});
