import { default as buildInfoFoxh2ZF2oTMeta } from "/workspace/src/nuxt/pages/buildInfo.vue?macro=true";
import { default as indexBV36AOvCKWMeta } from "/workspace/src/nuxt/pages/dashboard/index.vue?macro=true";
import { default as deactivateHVBeFTfEW3Meta } from "/workspace/src/nuxt/pages/dashboard/login/deactivate.vue?macro=true";
import { default as subscriptionsIGjsiSz2utMeta } from "/workspace/src/nuxt/pages/dashboard/subscriptions.vue?macro=true";
import { default as contactusLkFGxlheiCMeta } from "/workspace/src/nuxt/pages/dashboard/support/contactus.vue?macro=true";
import { default as emailM0EmT0btA8Meta } from "/workspace/src/nuxt/pages/dashboard/user/email.vue?macro=true";
import { default as index95P5Wae18ZMeta } from "/workspace/src/nuxt/pages/dashboard/user/index.vue?macro=true";
import { default as usernameBuHILyWD6XMeta } from "/workspace/src/nuxt/pages/dashboard/user/username.vue?macro=true";
import { default as userprefsvbZWelgeTZMeta } from "/workspace/src/nuxt/pages/dashboard/userprefs.vue?macro=true";
import { default as indexOOjppT1vIqMeta } from "/workspace/src/nuxt/pages/index.vue?macro=true";
import { default as resetPasswordN4yM0eb3D7Meta } from "/workspace/src/nuxt/pages/resetPassword.vue?macro=true";
import { default as verifiedl6fSpEPrF1Meta } from "/workspace/src/nuxt/pages/verified.vue?macro=true";
export default [
  {
    name: "buildInfo",
    path: "/buildInfo",
    component: () => import("/workspace/src/nuxt/pages/buildInfo.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/workspace/src/nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-login-deactivate",
    path: "/dashboard/login/deactivate",
    component: () => import("/workspace/src/nuxt/pages/dashboard/login/deactivate.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subscriptions",
    path: "/dashboard/subscriptions",
    component: () => import("/workspace/src/nuxt/pages/dashboard/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-support-contactus",
    path: "/dashboard/support/contactus",
    component: () => import("/workspace/src/nuxt/pages/dashboard/support/contactus.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-user-email",
    path: "/dashboard/user/email",
    component: () => import("/workspace/src/nuxt/pages/dashboard/user/email.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-user",
    path: "/dashboard/user",
    component: () => import("/workspace/src/nuxt/pages/dashboard/user/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-user-username",
    path: "/dashboard/user/username",
    component: () => import("/workspace/src/nuxt/pages/dashboard/user/username.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-userprefs",
    path: "/dashboard/userprefs",
    component: () => import("/workspace/src/nuxt/pages/dashboard/userprefs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/src/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "resetPassword",
    path: "/resetPassword",
    component: () => import("/workspace/src/nuxt/pages/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "verified",
    path: "/verified",
    component: () => import("/workspace/src/nuxt/pages/verified.vue").then(m => m.default || m)
  }
]