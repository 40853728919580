import { defineNuxtPlugin } from "nuxt/app";
import { SubscriptionService } from "../services/SubscriptionService";
import { Capacitor } from "@capacitor/core";

export default defineNuxtPlugin((nuxtApp) => {
    if (Capacitor.getPlatform() === "web") {
        console.log("Web platform detected; Cordova plugins not available");
    } else {
        const config = nuxtApp.$config;
        const subscriptionService = new SubscriptionService(config);
        subscriptionService.initializeCordovaStore();
        nuxtApp.vueApp.config.globalProperties.$subscriptionService =
            subscriptionService;
    }
});
