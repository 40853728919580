export const questions = [
    {
        first_time: true,
        id: 0,
        node: -5,
        options: {
            ar: {
                answers: [
                    "لتسريع نقل البيانات",
                    "لتجنب الفاعلين السيئين تمامًا",
                    "لإبقاء البيانات غير قابلة للقراءة للآخرين",
                    "لتقليل التوقف أثناء النقل"
                ],
                correctAnswer: "لإبقاء البيانات غير قابلة للقراءة للآخرين"
            },
            en: {
                answers: [
                    "To speed up data transfers.",
                    "To avoid bad actors entirely.",
                    "To keep data unreadable to others.",
                    "To reduce stops during transmission."
                ],
                correctAnswer: "To keep data unreadable to others."
            },
            fr: {
                answers: [
                    "Accélérer les transferts de données.",
                    "Éviter complètement les acteurs malveillants.",
                    "Rendre les données illisibles pour les autres.",
                    "Réduire les arrêts pendant la transmission."
                ],
                correctAnswer: "Rendre les données illisibles pour les autres."
            },
            tr: {
                answers: [
                    "Veri aktarımını hızlandırmak.",
                    "Kötü aktörlerden tamamen kaçınmak.",
                    "Verileri başkalarına okunamaz hale getirmek.",
                    "İletim sırasında duraklamaları azaltmak."
                ],
                correctAnswer: "Verileri başkalarına okunamaz hale getirmek."
            }
        },
        question: {
            ar: "لماذا يعتبر التشفير مهمًا لتأمين البيانات على الإنترنت؟",
            en: "Why is encryption important for securing data on the Internet?",
            fr: "Pourquoi le cryptage est-il important pour sécuriser les données sur Internet ?",
            tr: "İnternetteki verilerin güvenliğini sağlamak için şifreleme neden önemlidir?"
        },
        quiz_type: "Squares",
        slot: 0
    },
    {
        first_time: true,
        id: 1,
        node: -5,
        options: {
            ar: {
                answers: [
                    "الرسائل تستغرق وقتًا طويلاً لفك تشفيرها.",
                    "يجب أيضًا مشاركة المفتاح.",
                    "يمكنه فقط تشفير الرسائل القصيرة.",
                    "يتطلب أدوات متعددة لفك التشفير."
                ],
                correctAnswer: "يجب أيضًا مشاركة المفتاح."
            },
            en: {
                answers: [
                    "Messages take too long to decode.",
                    "The key must also be shared.",
                    "It can only encrypt short messages.",
                    "Requires multiple tools for decryption."
                ],
                correctAnswer: "The key must also be shared."
            },
            fr: {
                answers: [
                    "Les messages prennent trop de temps à décoder.",
                    "La clé doit également être partagée.",
                    "Il ne peut crypter que des messages courts.",
                    "Nécessite plusieurs outils pour le décryptage."
                ],
                correctAnswer: "La clé doit également être partagée."
            },
            tr: {
                answers: [
                    "Mesajların çözülmesi çok uzun sürer.",
                    "Anahtar da paylaşılmalıdır.",
                    "Sadece kısa mesajları şifreleyebilir.",
                    "Birden fazla araç gerektirir."
                ],
                correctAnswer: "Anahtar da paylaşılmalıdır."
            }
        },
        question: {
            ar: "ما هي المشكلة الرئيسية مع Scytale و Symmetric Encryption؟",
            en: "What is the main problem with the Scytale and Symmetric Encryption?",
            fr: "Quel est le principal problème avec le Scytale et le cryptage symétrique ?",
            tr: "Scytale ve Simetrik Şifreleme ile ilgili ana sorun nedir?"
        },
        quiz_type: "Squares",
        slot: 1
    },
    {
        first_time: true,
        id: 2,
        node: -5,
        options: {
            ar: {
                answers: [
                    "يتجنب مشاركة مفاتيح التشفير.",
                    "يسرّع الاتصالات الآمنة.",
                    "يتطلب وجود جاسوس واحد فقط.",
                    "يجعل الأقفال الفيزيائية غير ضرورية."
                ],
                correctAnswer: "يتجنب مشاركة مفاتيح التشفير."
            },
            en: {
                answers: [
                    "It avoids sharing encryption keys.",
                    "It speeds up secure communication.",
                    "It requires only one spy to act.",
                    "It makes physical locks unnecessary."
                ],
                correctAnswer: "It avoids sharing encryption keys."
            },
            fr: {
                answers: [
                    "Évite le partage des clés de cryptage.",
                    "Accélère la communication sécurisée.",
                    "Nécessite seulement un espion.",
                    "Rend les verrous physiques inutiles."
                ],
                correctAnswer: "Évite le partage des clés de cryptage."
            },
            tr: {
                answers: [
                    "Şifreleme anahtarlarını paylaşmaktan kaçınır.",
                    "Güvenli iletişimi hızlandırır.",
                    "Sadece bir casusun hareket etmesini gerektirir.",
                    "Fiziksel kilitleri gereksiz kılar."
                ],
                correctAnswer: "Şifreleme anahtarlarını paylaşmaktan kaçınır."
            }
        },
        question: {
            ar: "ما هي الميزة الرئيسية لـ Asymmetric Encryption؟",
            en: "What is the key advantage of Asymmetric Encryption?",
            fr: "Quel est l'avantage clé du cryptage asymétrique ?",
            tr: "Asimetrik Şifrelemenin ana avantajı nedir?"
        },
        quiz_type: "Squares",
        slot: 2
    },
    {
        first_time: true,
        id: 3,
        node: -5,
        options: {
            ar: {
                answers: [
                    "يتم مشاركتها فقط مع المرسلين الموثوق بهم.",
                    "لا يمكن استنتاجها رياضياً.",
                    "يتم ترميزها داخل المفتاح العام.",
                    "تتغير مع كل استخدام."
                ],
                correctAnswer: "لا يمكن استنتاجها رياضياً."
            },
            en: {
                answers: [
                    "It’s only shared with trusted senders.",
                    "It’s kept secret and impossible to deduce.",
                    "It’s encoded within the Public Key.",
                    "It changes with every use."
                ],
                correctAnswer: "It’s kept secret and impossible to deduce."
            },
            fr: {
                answers: [
                    "Elle est seulement partagée avec des expéditeurs de confiance.",
                    "Elle est gardée secrète et impossible à déduire.",
                    "Elle est encodée dans la clé publique.",
                    "Elle change à chaque utilisation."
                ],
                correctAnswer:
                    "Elle est gardée secrète et impossible à déduire."
            },
            tr: {
                answers: [
                    "Sadece güvenilir göndericilerle paylaşılır.",
                    "Gizli tutulur ve çözülmesi imkansızdır.",
                    "Genel Anahtar içinde kodlanmıştır.",
                    "Her kullanımda değişir."
                ],
                correctAnswer: "Gizli tutulur ve çözülmesi imkansızdır."
            }
        },
        question: {
            ar: "لماذا يعتبر المفتاح الخاص في التشفير غير متماثل آمنًا؟",
            en: "Why is the Private Key in Asymmetric Encryption secure?",
            fr: "Pourquoi la clé privée en cryptage asymétrique est-elle sécurisée ?",
            tr: "Asimetrik Şifrelemede Özel Anahtar neden güvenlidir?"
        },
        quiz_type: "Squares",
        slot: 3
    }
];

export const scene_text = {
    en: {
        Scene1: {
            text: [
                "Encryption is one of the most important technologies ever built and we rely on it every day.",
                "Encryption locks our data up and prevents unauthorized access from nefarious actors like hackers and big corporations."
            ]
        },
        Scene2: {
            text: [
                "Instead of trying to sneak our data past bad actors, encryption makes our data completely unreadable so it can flow right by them in the open.",
                "The Internet is a web of cables and servers that stretches across the globe.",
                "When we send data to one another across the Internet, it makes multiple stops and passes through hundreds of devices along its path.",
                "Imagine shipping a parcel to a friend, it would pass through multiple depots along the way, where anyone interested could cut through the tape.",
                "Encryption makes our data completely unreadable, effectively locking it up to everyone but those with the key."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "We have relied on encryption throughout history, and one of the earliest uses of encryption was by the clever Spartans in ancient Greece.",
                "As Sparta continued to expand its control over Greece, their dependence on messengers to send battle plans and updates grew every day. Enemies started to notice and began intercepting messengers to steal their sensitive information.",
                "It was starting to become a problem, battles were being lost, and the generals needed a way to hide their messages. They devised an ingenious tool, the Scytale. It is one of the earliest examples of encryption.",
                "Spartan generals would wrap a long strip of leather around a wooden rod and inscribe their battle plans across the bands of leather, one letter at a time.",
                "When unwrapped, the leather strip appeared to contain a random sequence of letters. Without a scytale of the exact same size, it was challenging to align the letters properly and uncover the hidden message.",
                'Messengers disguised the hidden message as a leather belt and carried the seemingly "meaningless" scytale across enemy lines. This method worked well, and kept sensitive messages out of the enemy\'s hands.',
                "This is an example of Symmetric Encryption. It uses the same key to lock (encrypt) and unlock (decrypt) the message. It’s simple and quick, and sending the key alongside the message meant the process was incredibly fast.",
                "Unfortunately for the Spartans, their enemies started to get suspicious as hundreds of wooden rods and inscribed leather belts passed through their territory. Eventually their enemies figured out how to use the scytales, and began decrypting the sensitive battle plans.",
                "This highlights the biggest problem with Symmetric Encryption: the key has to be sent to the recipient at some point. For a long time, this seemed like an unsolvable issue—until the 20th century."
            ],
            fast: "FAST",
            dialog1: "Are those enemy plans?!",
            dialog2: "No enemy plans detected...",
            dialog3: "Move along!",
            dialog4: "Wait!",
            dialog5: "That's a Scytale!",
            insecure: "INSECURE",
            encrypted: "ENCRYPTED",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESSAGE DELIVERED!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "SHARE YOUR BEST SCORE!",
            tutorial: "TAP TO SWITCH LANES",
            tutorial2: "DODGE GUARDS AND DELIVER SCYTALES!",
            tryAgain: "TRY AGAIN",
            luckRollSuccess: "You're clear.\nMove on.",
            luckRollFail: "THIS IS A SCYTALE!\nGET BACK HERE!",
            coinFlipHeader: "LET FATE DECIDE!",
            coinFlipSuccess: "YOU'RE\nSAFE!",
            coinFlipFail: "THEY\nFOUND\nTHE\nMESSAGE!"
        },
        Scene7: {
            slow: "SLOW",
            text: [
                "Jump ahead to the Cold War, an era of high-stakes secrecy, where even one misstep in communication could have disrupted the delicate balance between the United States and the Soviet Union.",
                "Two rival spies, one from the United States and the other from the Soviet Union, are working together to establish a peace treaty that could finally bring an end to this bloody war.",
                "Trust was scarce, and neither side wanted to rely Symmetric Encryption. Sending a key to one another was too risky, so they devised a clever plan.",
                "The American spy placed the signed peace treaty inside a sturdy briefcase, secured it with their own padlock, and then left the locked case on a park bench for their Soviet counterpart to discreetly collect.",
                "The Soviet agent retrieved the locked briefcase, added their own padlock alongside the American one, and quietly returned the briefcase to the park bench, maintaining its security at all times.",
                "The American spy picked the briefcase back up and removed their original padlock, still locked by the Soviet’s padlock. They then returned the briefcase to the bench, confident it had stayed sealed throughout the exchange.",
                "Finally, the Soviet agent retrieved the briefcase once more, removed their own lock, and opened it to reveal the signed peace treaty—securely delivered and ready to bring the war to an end.",
                "This process is an example of Asymmetric Encryption. It overcomes the biggest flaw of symmetric encryption by eliminating the need to send the key at all, making it far more secure.",
                "However, this added security comes at a cost—it’s slow. To securely send a message using this method, it must travel back and forth three times, tripling the time it takes to communicate."
            ],
            secure: "SECURE",
            tooSensitive: "Too sensitive",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "SHARE YOUR BEST SCORE!",
            spyTutorialText: "I was just kidding...",
            tryAgain: "TRY AGAIN"
        },
        Scene10: {
            text: [
                "Modern society needed an encryption method that was both secure and fast to meet the demands of the newly emerging Internet. In the 1970s, a breakthrough idea emerged: instead of using two locks to send a message, why not simplify the process with just one.",
                "Blue wants to communicate with their friend Red, so they start by sharing a blueprint of their lock publicly for anyone to recreate. However, they keep the key to that lock private and secure.",
                "Red copies that blueprint and recreates a copy of Blue's padlock.",
                "Next they use that lock to secure a message to be sent to Blue knowing that they already have the key to unlock it.",
                "The lock and key correspond to two big numbers that work together in a brilliant way: one number is a Public Key, and the other is a Private Key.",
                "Anyone interested in sending a message can use simple math along with the Public Key to scramble a message, that only the corresponding secret Private Key can unscramble. The magic is that it’s nearly impossible for an attacker to determine the Private Key from the Public Key.",
                "This is an example of modern Asymmetric Encryption, specifically RSA. Returning to a single-lock system drastically simplified the exchange process and revolutionized the Internet by enabling fast and secure communication.",
                "Since the 1970s, encryption has evolved with even more uncrackable algorithms. RSA, SHA, and now ECC have emerged as some of the most important technologies ever developed."
            ],
            publicKey: "PUBLIC KEY",
            privateKey: "PRIVATE KEY",
            secure: "SECURE",
            fast: "FAST",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "SHARE YOUR BEST SCORE!",
            tutorial: "TAP AND HOLD\nTO MOVE AND SHOOT!",
            tryAgain: "TRY AGAIN"
        },
        Scene13: {}
    },
    fr: {
        Scene1: {
            text: [
                "Le chiffrement est l'une des technologies les plus importantes jamais créées et nous y comptons chaque jour.",
                "Le chiffrement sécurise nos données et empêche l'accès non autorisé d'acteurs malveillants comme les pirates et les grandes entreprises."
            ]
        },
        Scene2: {
            text: [
                "Au lieu d'essayer de cacher nos données aux acteurs malveillants, le chiffrement rend nos données complètement illisibles afin qu'elles puissent passer sous leurs yeux sans problème.",
                "Internet est un réseau de câbles et de serveurs qui s'étend à travers le globe.",
                "Lorsque nous envoyons des données sur Internet, elles font plusieurs arrêts et passent par des centaines d'appareils le long de leur chemin.",
                "Imaginez expédier un colis à un ami ; il passerait par plusieurs dépôts en cours de route, où toute personne intéressée pourrait ouvrir le ruban adhésif.",
                "Le chiffrement rend nos données complètement illisibles, les sécurisant pour tous sauf pour ceux qui ont la clé."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "Nous avons utilisé le chiffrement tout au long de l'histoire, et l'une des premières utilisations du chiffrement a été faite par les Spartes ingénieux de la Grèce antique.",
                "Alors que Sparte étendait son contrôle sur la Grèce, sa dépendance aux messagers pour envoyer des plans de bataille et des mises à jour augmentait chaque jour. Les ennemis ont commencé à intercepter les messagers pour voler leurs informations sensibles.",
                "Cela devenait un problème : des batailles étaient perdues, et les généraux avaient besoin d'une méthode pour cacher leurs messages. Ils ont inventé un outil ingénieux : le Scytale. C'est l'un des premiers exemples de chiffrement.",
                "Les généraux spartiates enroulaient une longue bande de cuir autour d'un bâton en bois et inscrivaient leurs plans de bataille à travers les bandes de cuir, une lettre à la fois.",
                "Une fois déroulée, la bande de cuir semblait contenir une séquence aléatoire de lettres. Sans un scytale de la même taille exacte, il était difficile d'aligner correctement les lettres et de révéler le message caché.",
                'Les messagers dissimulaient le message caché en le faisant passer pour une ceinture de cuir et traversaient les lignes ennemies avec ce scytale apparemment "insignifiant". Cette méthode fonctionnait bien et gardait les messages sensibles hors de portée des ennemis.',
                "Ceci est un exemple de chiffrement symétrique. Il utilise la même clé pour verrouiller (chiffrer) et déverrouiller (déchiffrer) le message. C'est simple et rapide, et envoyer la clé avec le message rendait le processus incroyablement rapide.",
                "Malheureusement pour les Spartiates, leurs ennemis ont commencé à se méfier lorsque des centaines de bâtons en bois et de ceintures en cuir gravées traversaient leur territoire. Finalement, leurs ennemis ont découvert comment utiliser les scytales et ont commencé à décrypter les plans de bataille sensibles.",
                "Cela souligne le plus grand problème du chiffrement symétrique : la clé doit être envoyée au destinataire à un moment donné. Pendant longtemps, cela semblait être un problème insoluble - jusqu'au XXe siècle."
            ],
            fast: "RAPIDE",
            dialog1: "Ce sont des plans ennemis ?!",
            dialog2: "Aucun plan ennemi détecté...",
            dialog3: "Circulez !",
            dialog4: "Attendez !",
            dialog5: "C'est un Scytale !",
            insecure: "NON SÉCURISÉ",
            encrypted: "CRYPTÉ",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESSAGE LIVRÉ !",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            tutorial: "APPUYEZ POUR CHANGER DE LIGNE",
            tutorial2: "ÉVITEZ LES GARDES ET LIVREZ LES SCYTALES !",
            tryAgain: "ESSAYEZ À NOUVEAU",
            luckRollSuccess: "C'est bon.\nContinuez.",
            luckRollFail: "C'EST UN SCYTALE !\nREVENEZ ICI !",
            coinFlipHeader: "LAISSEZ LE DESTIN DÉCIDER !",
            coinFlipSuccess: "VOUS ÊTES\nEN SÉCURITÉ !",
            coinFlipFail: "ILS\nONT\nTROUVÉ\nLE MESSAGE !"
        },
        Scene7: {
            slow: "LENT",
            text: [
                "Avancez jusqu'à la Guerre froide, une époque de secret à enjeux élevés, où une seule erreur de communication aurait pu perturber l'équilibre délicat entre les États-Unis et l'Union soviétique.",
                "Deux espions rivaux, l'un des États-Unis et l'autre de l'Union soviétique, travaillent ensemble pour établir un traité de paix qui pourrait enfin mettre fin à cette guerre sanglante.",
                "La confiance était rare, et aucun des deux côtés ne voulait compter sur le chiffrement symétrique. Envoyer une clé à l'autre était trop risqué, alors ils ont imaginé un plan astucieux.",
                "L'espion américain a placé le traité de paix signé dans une mallette robuste, l'a sécurisée avec son propre cadenas, puis a laissé la mallette verrouillée sur un banc de parc pour que son homologue soviétique la récupère discrètement.",
                "L'agent soviétique a récupéré la mallette verrouillée, a ajouté son propre cadenas à côté de celui de l'Américain, et a tranquillement remis la mallette sur le banc, en maintenant sa sécurité à tout moment.",
                "L'espion américain a repris la mallette et a retiré son cadenas d'origine, encore verrouillée par celui du Soviétique. Il a ensuite remis la mallette sur le banc, convaincu qu'elle était restée scellée tout au long de l'échange.",
                "Enfin, l'agent soviétique a récupéré la mallette une fois de plus, a retiré son propre cadenas, et l'a ouverte pour révéler le traité de paix signé—livré en toute sécurité et prêt à mettre fin à la guerre.",
                "Ce processus est un exemple de chiffrement asymétrique. Il surmonte le plus grand défaut du chiffrement symétrique en éliminant le besoin d'envoyer la clé, le rendant beaucoup plus sûr.",
                "Cependant, cette sécurité supplémentaire a un coût : c'est lent. Pour envoyer un message en toute sécurité à l'aide de cette méthode, il doit faire trois allers-retours, triplant le temps nécessaire pour communiquer."
            ],
            secure: "SÉCURISÉ",
            tooSensitive: "Trop sensible",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            spyTutorialText: "Je plaisantais...",
            tryAgain: "ESSAYEZ À NOUVEAU"
        },
        Scene10: {
            text: [
                "La société moderne avait besoin d'une méthode de chiffrement à la fois sûre et rapide pour répondre aux exigences du nouvel Internet émergent. Dans les années 1970, une idée révolutionnaire a émergé : au lieu d'utiliser deux cadenas pour envoyer un message, pourquoi ne pas simplifier le processus avec un seul.",
                "Blue veut communiquer avec son ami Red, alors il commence par partager un plan de son cadenas publiquement pour que n'importe qui puisse le recréer. Cependant, il garde la clé de ce cadenas privée et sécurisée.",
                "Red copie ce plan et recrée une copie du cadenas de Blue.",
                "Ensuite, il utilise ce cadenas pour sécuriser un message à envoyer à Blue, sachant qu'il a déjà la clé pour le déverrouiller.",
                "Le cadenas et la clé correspondent à deux grands nombres qui fonctionnent ensemble de manière brillante : un nombre est une clé publique, et l'autre est une clé privée.",
                "Quiconque souhaite envoyer un message peut utiliser des mathématiques simples avec la clé publique pour brouiller un message, que seule la clé privée secrète correspondante peut déchiffrer. La magie est qu'il est presque impossible pour un attaquant de déterminer la clé privée à partir de la clé publique.",
                "Ceci est un exemple de chiffrement asymétrique moderne, spécifiquement RSA. Revenir à un système à un seul cadenas a considérablement simplifié le processus d'échange et révolutionné Internet en permettant une communication rapide et sécurisée.",
                "Depuis les années 1970, le chiffrement a évolué avec des algorithmes encore plus inviolables. RSA, SHA et maintenant ECC sont apparus comme certaines des technologies les plus importantes jamais développées."
            ],
            publicKey: "CLÉ PUBLIQUE",
            privateKey: "CLÉ PRIVÉE",
            secure: "SÉCURISÉ",
            fast: "RAPIDE",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            tutorial: "APPUYEZ ET MAINTENEZ\nPOUR DÉPLACER ET TIRER !",
            tryAgain: "ESSAYEZ À NOUVEAU"
        },
        Scene13: {}
    },
    ar: {
        Scene1: {
            text: [
                "التشفير هو واحدة من أهم التقنيات التي تم بناؤها على الإطلاق ونحن نعتمد عليها يوميًا.",
                "يقوم التشفير بحماية بياناتنا ويمنع الوصول غير المصرح به من الجهات الخبيثة مثل القراصنة والشركات الكبرى."
            ]
        },
        Scene2: {
            text: [
                "بدلاً من محاولة تمرير بياناتنا بعيدًا عن الجهات الخبيثة، يجعل التشفير بياناتنا غير قابلة للقراءة تمامًا بحيث يمكنها المرور بجانبهم في العلن.",
                "الإنترنت هو شبكة من الكابلات والخوادم الممتدة عبر العالم.",
                "عندما نرسل بيانات عبر الإنترنت، تمر بعدة نقاط توقف وتمر عبر مئات الأجهزة في طريقها.",
                "تخيل أنك ترسل طردًا إلى صديق؛ سيمر عبر عدة مراكز توزيع في الطريق، حيث يمكن لأي شخص مهتم أن يفتح الشريط اللاصق.",
                "التشفير يجعل بياناتنا غير قابلة للقراءة تمامًا، مما يؤمنها لأي شخص باستثناء من لديه المفتاح."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "اعتمدنا على التشفير عبر التاريخ، وكان من أوائل استخداماته من قبل الإسبرطيين الماكرين في اليونان القديمة.",
                "مع استمرار إسبرطة في توسيع سيطرتها على اليونان، زاد اعتمادهم على الرسل لإرسال خطط المعارك والتحديثات اليومية. بدأ الأعداء في ملاحظة ذلك واعترضوا الرسل لسرقة معلوماتهم الحساسة.",
                "أصبحت المشكلة متفاقمة، حيث بدأت المعارك تُخسر، وكان القادة بحاجة إلى طريقة لإخفاء رسائلهم. فاخترعوا أداة ذكية، وهي السكيتال. إنه أحد أقدم الأمثلة على التشفير.",
                "كان الجنرالات الإسبرطيون يلفون شريطًا طويلًا من الجلد حول عصا خشبية ويكتبون خططهم الحربية على الشرائط الجلدية، حرفًا واحدًا في كل مرة.",
                "عند فك الشريط، يبدو كأنه يحتوي على تسلسل عشوائي من الأحرف. بدون سكيتال بنفس الحجم بالضبط، كان من الصعب ترتيب الحروف بشكل صحيح وكشف الرسالة المخفية.",
                'قام الرسل بإخفاء الرسالة المشفرة على شكل حزام جلدي ونقلوا السكيتال الذي يبدو "عديم المعنى" عبر خطوط العدو. هذه الطريقة كانت فعالة وحافظت على الرسائل الحساسة بعيدًا عن أيدي الأعداء.',
                "هذا مثال على التشفير المتماثل. يستخدم نفس المفتاح لتشفير (قفل) وفك تشفير (فتح) الرسالة. إنه بسيط وسريع، وإرسال المفتاح مع الرسالة جعل العملية سريعة للغاية.",
                "للأسف بالنسبة للإسبرطيين، بدأ أعداؤهم بالشك مع مرور مئات القضبان الخشبية والأحزمة الجلدية المنقوشة عبر أراضيهم. في النهاية، اكتشف الأعداء كيفية استخدام السكيتال وبدأوا في فك تشفير خطط المعارك الحساسة.",
                "هذا يبرز المشكلة الأكبر في التشفير المتماثل: يجب إرسال المفتاح إلى المستلم في وقت ما. لفترة طويلة، بدا هذا مشكلة غير قابلة للحل - حتى القرن العشرين."
            ],
            fast: "سريع",
            dialog1: "هل هذه خطط العدو؟!",
            dialog2: "لم يتم اكتشاف أي خطط للعدو...",
            dialog3: "تابع التحرك!",
            dialog4: "انتظر!",
            dialog5: "إنها سكيتال!",
            insecure: "غير آمن",
            encrypted: "مشفّر",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "تم توصيل الرسالة!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "شارك أفضل نتيجتك!",
            tutorial: "اضغط للتبديل بين المسارات",
            tutorial2: "تجنب الحراس ووصّل السكيتال!",
            tryAgain: "حاول مرة أخرى",
            luckRollSuccess: "أنت بأمان.\nتابع التحرك.",
            luckRollFail: "هذه سكيتال!\nارجع إلى هنا!",
            coinFlipHeader: "دع القدر يقرر!",
            coinFlipSuccess: "أنت\nبأمان!",
            coinFlipFail: "لقد\nاكتشفوا\nالرسالة!"
        },
        Scene7: {
            slow: "بطيء",
            text: [
                "انتقل إلى الحرب الباردة، حقبة السرية الشديدة، حيث يمكن لخطأ واحد في الاتصال أن يخل بالتوازن الحساس بين الولايات المتحدة والاتحاد السوفيتي.",
                "جاسوسان متنافسان، أحدهما من الولايات المتحدة والآخر من الاتحاد السوفيتي، يتعاونان لإقامة معاهدة سلام يمكن أن تنهي هذه الحرب الدموية.",
                "كانت الثقة نادرة، ولم يرغب أي من الطرفين في الاعتماد على التشفير المتماثل. كان إرسال مفتاح للآخر محفوفًا بالمخاطر، لذا توصلوا إلى خطة ذكية.",
                "قام الجاسوس الأمريكي بوضع معاهدة السلام الموقعة داخل حقيبة معدنية متينة، وقفلها بقفله الخاص، ثم ترك الحقيبة المقفلة على مقعد في الحديقة ليقوم الجاسوس السوفيتي بجمعها سرًا.",
                "استعاد العميل السوفيتي الحقيبة المقفلة، وأضاف قفله الخاص بجانب قفل الأمريكي، ثم أعاد الحقيبة إلى المقعد، محافظًا على أمانها طوال الوقت.",
                "أخذ الجاسوس الأمريكي الحقيبة مرة أخرى وأزال قفله الأصلي، مع إبقائها مقفلة بقفل السوفيتي. ثم أعاد الحقيبة إلى المقعد، واثقًا أنها بقيت محكمة الإغلاق طوال التبادل.",
                "أخيرًا، استعاد العميل السوفيتي الحقيبة مرة أخرى، وأزال قفله الخاص، وفتحها ليكشف عن معاهدة السلام الموقعة - تم تسليمها بأمان وجاهزة لإنهاء الحرب.",
                "هذه العملية مثال على التشفير غير المتماثل. يتغلب على العيب الأكبر للتشفير المتماثل من خلال التخلص من الحاجة لإرسال المفتاح تمامًا، مما يجعله أكثر أمانًا.",
                "ومع ذلك، يأتي هذا الأمان الإضافي بتكلفة - فهو بطيء. لإرسال رسالة بأمان باستخدام هذه الطريقة، يجب أن تمر بثلاث مراحل، مما يضاعف الوقت اللازم للاتصال."
            ],
            secure: "آمن",
            tooSensitive: "حساس جدًا",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "شارك أفضل نتيجتك!",
            spyTutorialText: "كنت أمزح فقط...",
            tryAgain: "حاول مرة أخرى"
        },
        Scene10: {
            text: [
                "احتاج المجتمع الحديث إلى طريقة تشفير تكون آمنة وسريعة في نفس الوقت لتلبية متطلبات الإنترنت الناشئ حديثًا. في السبعينيات، ظهرت فكرة مبتكرة: بدلاً من استخدام قفلين لإرسال رسالة، لماذا لا يتم تبسيط العملية بقفل واحد فقط.",
                "يريد Blue التواصل مع صديقه Red، لذا يبدأ بمشاركة مخطط القفل الخاص به علنًا ليتمكن أي شخص من إعادة إنشائه. ومع ذلك، يحتفظ بالمفتاح لذلك القفل خاصًا وآمنًا.",
                "يقوم Red بنسخ المخطط ويعيد إنشاء نسخة من قفل Blue.",
                "بعد ذلك، يستخدم القفل لتأمين رسالة لإرسالها إلى Blue مع العلم أن لديه المفتاح لفتحها.",
                "يمثل القفل والمفتاح رقمين كبيرين يعملان معًا بطريقة ذكية: الرقم الأول هو المفتاح العام، والثاني هو المفتاح الخاص.",
                "أي شخص مهتم بإرسال رسالة يمكنه استخدام الرياضيات البسيطة مع المفتاح العام لتشفير الرسالة، والتي يمكن فك تشفيرها فقط بواسطة المفتاح الخاص السري المقابل. السحر يكمن في أنه من المستحيل تقريبًا للمهاجم أن يحدد المفتاح الخاص من المفتاح العام.",
                "هذا مثال على التشفير غير المتماثل الحديث، وتحديدًا RSA. العودة إلى نظام القفل الواحد بسّطت عملية التبادل بشكل كبير وأحدثت ثورة في الإنترنت من خلال تمكين الاتصال الآمن والسريع.",
                "منذ السبعينيات، تطور التشفير مع خوارزميات أكثر تعقيدًا. ظهرت RSA وSHA والآن ECC كواحدة من أهم التقنيات التي تم تطويرها على الإطلاق."
            ],
            publicKey: "المفتاح العام",
            privateKey: "المفتاح الخاص",
            secure: "آمن",
            fast: "سريع",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "شارك أفضل نتيجتك!",
            tutorial: "اضغط واستمر\nللتحرك وإطلاق النار!",
            tryAgain: "حاول مرة أخرى"
        },
        Scene13: {}
    },
    tr: {
        Scene1: {
            text: [
                "Şifreleme, şimdiye kadar geliştirilmiş en önemli teknolojilerden biridir ve her gün ona güveniyoruz.",
                "Şifreleme, verilerimizi korur ve bilgisayar korsanları ve büyük şirketler gibi kötü niyetli aktörlerin yetkisiz erişimini engeller."
            ]
        },
        Scene2: {
            text: [
                "Kötü niyetli aktörlerin verilerimize gizlice erişmeye çalışması yerine, şifreleme verilerimizi tamamen okunamaz hale getirir, böylece açıkta onların yanından geçebilir.",
                "İnternet, dünya çapında uzanan bir kablo ve sunucu ağıdır.",
                "İnternet üzerinden veri gönderdiğimizde, yol boyunca birçok durakta durur ve yüzlerce cihazdan geçer.",
                "Bir arkadaşınıza bir paket gönderdiğinizi hayal edin; bu paket, yolda birçok depodan geçer ve ilgilenen biri bantı kolayca açabilir.",
                "Şifreleme, verilerimizi tamamen okunamaz hale getirir ve sadece anahtara sahip olanlar dışında herkese kilitler."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "Tarih boyunca şifrelemeye güvendik ve şifrelemenin en erken kullanımlarından biri antik Yunan’da zeki Spartalılar tarafından gerçekleştirilmiştir.",
                "Sparta, Yunanistan üzerindeki kontrolünü genişletmeye devam ederken, savaş planlarını ve güncellemeleri göndermek için habercilere olan bağımlılığı her geçen gün arttı. Düşmanlar bunu fark etmeye başladı ve hassas bilgileri çalmak için habercileri yakalamaya başladılar.",
                "Bu bir sorun haline gelmeye başladı; savaşlar kaybediliyordu ve generaller mesajlarını saklamanın bir yolunu bulmaları gerekiyordu. Dahiyane bir araç geliştirdiler: Skytale. Bu, şifrelemenin en erken örneklerinden biridir.",
                "Spartalı generaller, uzun bir deri şeridini bir ahşap çubuğun etrafına sarar ve savaş planlarını deri şeritler boyunca birer birer yazarak kodlardı.",
                "Şerit açıldığında, rastgele harflerden oluşan bir dizi gibi görünüyordu. Tam olarak aynı boyutta bir skytale olmadan, harfleri doğru şekilde hizalamak ve gizli mesajı açığa çıkarmak zordu.",
                'Haberciler gizli mesajı deri bir kemer olarak sakladılar ve görünüşte "anlamsız" skytaleyi düşman hatlarının ötesine taşıdılar. Bu yöntem oldukça etkiliydi ve hassas mesajları düşmanın eline geçmekten korudu.',
                "Bu, Simetrik Şifreleme'nin bir örneğidir. Mesajı kilitlemek (şifrelemek) ve açmak (şifresini çözmek) için aynı anahtarı kullanır. Basit ve hızlıdır; anahtarı mesajla birlikte göndermek süreci inanılmaz derecede hızlı hale getirirdi.",
                "Ne yazık ki, Spartalılar için düşmanları yüzlerce ahşap çubuk ve deri kemerin topraklarından geçtiğini fark ettikçe şüphelenmeye başladılar. Sonunda, düşmanlar skytaleleri nasıl kullanacaklarını çözdüler ve hassas savaş planlarını şifrelerini çözmeye başladılar.",
                "Bu durum, Simetrik Şifreleme'nin en büyük sorununu ortaya çıkarıyor: Anahtar bir noktada alıcıya gönderilmek zorundadır. Uzun bir süre boyunca, bu çözülmesi imkansız bir sorun gibi görünüyordu - ta ki 20. yüzyıla kadar."
            ],
            fast: "HIZLI",
            dialog1: "Bunlar düşman planları mı?!",
            dialog2: "Hiçbir düşman planı tespit edilmedi...",
            dialog3: "Devam edin!",
            dialog4: "Bekleyin!",
            dialog5: "Bu bir Skytale!",
            insecure: "GÜVENSİZ",
            encrypted: "ŞİFRELİ",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESAJ TESLİM EDİLDİ!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            tutorial: "ŞERİTLERİ DEĞİŞTİRMEK İÇİN DOKUN",
            tutorial2: "MUHAFIZLARDAN KAÇ VE SKYTALE TESLİM ET!",
            tryAgain: "TEKRAR DENE",
            luckRollSuccess: "Temizsin.\nDevam et.",
            luckRollFail: "BU BİR SKYTALE!\nBURAYA GERİ GEL!",
            coinFlipHeader: "KADER KARAR VERSİN!",
            coinFlipSuccess: "GÜVENDESİN!",
            coinFlipFail: "MESAJI\nBULDULAR!"
        },
        Scene7: {
            slow: "YAVAŞ",
            text: [
                "Soğuk Savaş’a geçin; iletişimde tek bir hatanın bile Amerika Birleşik Devletleri ile Sovyetler Birliği arasındaki hassas dengeyi bozabileceği yüksek riskli bir gizlilik dönemi.",
                "Birbirine rakip iki casus, biri Amerika Birleşik Devletleri'nden ve diğeri Sovyetler Birliği'nden, bu kanlı savaşı nihayet sona erdirebilecek bir barış anlaşması oluşturmak için birlikte çalışıyor.",
                "Güven çok azdı ve hiçbir taraf Simetrik Şifreleme'ye güvenmek istemiyordu. Birbirine anahtar göndermek çok riskliydi, bu yüzden akıllıca bir plan yaptılar.",
                "Amerikalı casus, imzalanmış barış anlaşmasını sağlam bir çantanın içine koydu, kendi asma kilidiyle kilitledi ve çantayı Sovyet meslektaşının gizlice alması için bir park bankına bıraktı.",
                "Sovyet ajanı kilitli çantayı aldı, Amerikalının kilidinin yanına kendi asma kilidini ekledi ve çantayı tekrar bankta bırakarak her zaman güvenliğini sağladı.",
                "Amerikalı casus çantayı tekrar aldı ve orijinal kilidini çıkardı, çanta hâlâ Sovyet kilidiyle kilitliydi. Daha sonra çantayı tekrar bankta bıraktı, çantanın değiş tokuş boyunca mühürlü kaldığından emin olarak.",
                "Son olarak, Sovyet ajanı çantayı bir kez daha aldı, kendi kilidini çıkardı ve imzalanmış barış anlaşmasını açarak savaşın sona ermesine hazır hale getirdi.",
                "Bu işlem, Asimetrik Şifreleme'nin bir örneğidir. Simetrik şifrelemenin en büyük kusurunu, anahtarı gönderme ihtiyacını tamamen ortadan kaldırarak aşar, bu da onu çok daha güvenli hale getirir.",
                "Ancak bu ekstra güvenlik bir maliyetle gelir - yavaştır. Bu yöntemi kullanarak güvenli bir mesaj göndermek için mesaj üç kez gidip gelmek zorundadır, bu da iletişim süresini üç katına çıkarır."
            ],
            secure: "GÜVENLİ",
            tooSensitive: "Çok hassas",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            spyTutorialText: "Sadece şaka yapıyordum...",
            tryAgain: "TEKRAR DENE"
        },
        Scene10: {
            text: [
                "Modern toplum, yeni gelişen internetin taleplerini karşılamak için hem güvenli hem de hızlı bir şifreleme yöntemi gerekiyordu. 1970'lerde, devrim niteliğinde bir fikir ortaya çıktı: Bir mesaj göndermek için iki kilit kullanmak yerine, neden süreci yalnızca bir kilitle basitleştirmiyoruz?",
                "Blue, arkadaşı Red ile iletişim kurmak istiyor, bu yüzden kilidinin bir taslağını herkese açık bir şekilde paylaşarak herkesin bir kopyasını oluşturmasını sağlıyor. Ancak, o kilidin anahtarını özel ve güvende tutuyor.",
                "Red, taslağı kopyalar ve Blue'nun kilidinin bir kopyasını yeniden oluşturur.",
                "Ardından, o kilidi bir mesajı güvence altına almak için kullanır ve Blue’nun onu açacak anahtara sahip olduğundan emin olarak mesajı gönderir.",
                "Bu kilit ve anahtar, birlikte çalışan büyük iki sayı gibidir: biri genel anahtar, diğeri özel anahtardır.",
                "Mesaj göndermekle ilgilenen herkes, genel anahtarı kullanarak basit matematikle mesajı şifreleyebilir ve sadece eşleşen gizli özel anahtar onu şifresini çözebilir. Sihir, bir saldırganın genel anahtardan özel anahtarı belirlemesinin neredeyse imkânsız olmasında yatıyor.",
                "Bu, modern asimetrik şifreleme örneğidir, özellikle RSA. Tek kilit sistemine geri dönmek süreci büyük ölçüde basitleştirdi ve güvenli ve hızlı iletişimi mümkün kılarak internette devrim yarattı.",
                "1970'lerden beri şifreleme, daha karmaşık algoritmalarla gelişti. RSA, SHA ve şimdi ECC, şimdiye kadar geliştirilmiş en önemli teknolojilerden biri olarak ortaya çıktı."
            ],
            publicKey: "GENEL ANAHTAR",
            privateKey: "ÖZEL ANAHTAR",
            secure: "GÜVENLİ",
            fast: "HIZLI",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            tutorial: "HAREKET ETMEK VE ATEŞ ETMEK İÇİN DOKUN VE BASILI TUT!",
            tryAgain: "TEKRAR DENE"
        },
        Scene13: {}
    }
};
