import { defineNuxtPlugin } from "nuxt/app";
import { useUserStore } from "../store/userStore";
import { useGuestStore } from "../store/guestStore";
import { useNodeStore } from "../store/nodeStore";
import { useKeywordQuestionStore } from "../store/keywordQuestionStore";
import { Preferences } from "@capacitor/preferences";
import { checkRefreshExpiry } from "../utils/utils";
import { useRuntimeConfig } from "nuxt/app";
import type { NuxtConfig } from "nuxt/schema";

const setupUserServiceAndFetchData = async (
    userStore: any,
    guestStore: any,
    nodeStore: any,
    keywordQuestionStore: any,
    config: NuxtConfig
) => {
    try {
        const uStoreString = await Preferences.get({ key: "uStore" });
        const gStoreString = await Preferences.get({ key: "gStore" });
        const nStoreString = await Preferences.get({ key: "nStore" });
        const kqStoreString = await Preferences.get({ key: "kqStore" });
        if (gStoreString.value) {
            await guestStore.handleRehydration(JSON.parse(gStoreString.value));
        } else {
            guestStore.initGuestUser();
        }
        if (uStoreString.value) {
            await userStore.handleLoginResponse(JSON.parse(uStoreString.value));
            if (nStoreString.value) {
                await nodeStore.handleRehydration(
                    JSON.parse(nStoreString.value)
                );
            }
            if (kqStoreString.value) {
                await keywordQuestionStore.setKQs(
                    JSON.parse(kqStoreString.value)
                );
            }
            await checkRefreshExpiry();
            await userStore.updateUserDetailsAndPrefs();
            userStore.userService.saveCapTokens("user");
        }
    } catch (error) {
        console.error("Error during user setup and data fetching:", error);
        userStore.logout();
    }
};

export default defineNuxtPlugin(async (nuxtApp) => {
    const userStore = useUserStore();
    const guestStore = useGuestStore();
    const nodeStore = useNodeStore();
    const keywordQuestionStore = useKeywordQuestionStore();
    const config = useRuntimeConfig();
    await setupUserServiceAndFetchData(
        userStore,
        guestStore,
        nodeStore,
        keywordQuestionStore,
        config
    );
    userStore.setupUserService();
});
