import { defineNuxtPlugin } from "nuxt/app";
import { useGuestStore } from "../store/guestStore";
import { useUserStore } from "../store/userStore";
import { useModalStore } from "../store/modalStore";
import pkg from "../package.json";

export default defineNuxtPlugin(async (nuxtApp) => {
    const gStore = useGuestStore();
    const uStore = useUserStore();
    const mStore = useModalStore();
    const currentVersion = pkg.version;

    // Over engineered version comparison function
    function compareVersions(v1: string, v2: string): number {
        const v1Parts = v1.replace(/[^\d\.]+/g, "").split(".");
        const v2Parts = v2.replace(/[^\d\.]+/g, "").split(".");
        for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
            const part1 = parseInt(v1Parts[i]) || 0;
            const part2 = parseInt(v2Parts[i]) || 0;
            if (part1 !== part2) {
                return part1 - part2;
            }
        }

        const alphaSuffix1 = v1.replace(/^\d+(\.\d+)*\./, "");
        const alphaSuffix2 = v2.replace(/^\d+(\.\d+)*\./, "");
        if (alphaSuffix1 && alphaSuffix2) {
            if (alphaSuffix1 < alphaSuffix2) return -1;
            if (alphaSuffix1 > alphaSuffix2) return 1;
        } else if (alphaSuffix1) {
            return -1;
        } else if (alphaSuffix2) {
            return 1;
        }
        return 0;
    }

    if (!gStore.pkgVersion) {
        console.log(`Setting initial version: ${currentVersion}`);
        gStore.showVersionUpdateModal = false;
    } else if (compareVersions(gStore.pkgVersion, currentVersion) < 0) {
        console.log(`New version detected: ${currentVersion}`);
        gStore.clear();
        if (uStore.loggedIn) {
            if (mStore.isSubscriptionModalOpen) {
                mStore.toggleSubscriptionModal(false);
            }
            await uStore.logout();
        }
        gStore.showVersionUpdateModal = true;
    } else if (compareVersions(gStore.pkgVersion, currentVersion) > 0) {
        console.log(`Downgrade detected: ${currentVersion}`);
        gStore.showVersionUpdateModal = false;
    } else {
        console.log("Version is up to date");
        gStore.showVersionUpdateModal = false;
    }
    gStore.setPkgVersion(currentVersion);
});
