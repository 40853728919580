import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import { useUserStore } from "./userStore";
import type { FriendshipType, FriendUserType } from "../interfaces/friends";
import { useRuntimeConfig } from "nuxt/app";

interface State {
    friends: FriendUserType[];
    incomingRequests: FriendUserType[];
    outgoingRequests: FriendUserType[];
}

export const useFriendStore: StoreDefinition<"friends", State> = defineStore(
    "friends",
    {
        state: (): State => ({
            friends: [] as FriendUserType[],
            incomingRequests: [] as FriendUserType[],
            outgoingRequests: [] as FriendUserType[]
        }),
        getters: {
            getFriendByUsername: (state: State) => {
                return (username: string) =>
                    state.friends.find(
                        (friend) => friend.username === username
                    );
            },
            getFriendById: (state: State) => {
                return (id: number) =>
                    state.friends.find((friend) => friend.id === id);
            },
            getIncomingRequestByUsername: (state: State) => {
                return (username: string) =>
                    state.incomingRequests.find(
                        (friend) => friend.username === username
                    );
            },
            getOutgoingRequestByUsername: (state: State) => {
                return (username: string) =>
                    state.outgoingRequests.find(
                        (friend) => friend.username === username
                    );
            },
            getIncomingRequestById: (state: State) => {
                return (id: number) =>
                    state.incomingRequests.find((friend) => friend.id === id);
            },
            getOutgoingRequestById: (state: State) => {
                return (id: number) =>
                    state.outgoingRequests.find((friend) => friend.id === id);
            },
            cleanedState: (state: State) => {
                const { friends, incomingRequests, outgoingRequests } = state;
                return { friends, incomingRequests, outgoingRequests };
            }
        },
        actions: {
            async addFriend(username: string) {
                try {
                    const uStore = useUserStore();
                    const config = useRuntimeConfig();
                    const response = await fetch(
                        `${config.public.API_BASE_URL}auth/friendship/send_request/`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${uStore.accessToken}`
                            },
                            body: JSON.stringify({ username })
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        this.outgoingRequests.push(data.receiver);
                        return true;
                    } else {
                        console.error(
                            "Error sending friend request:",
                            await response.text()
                        );
                        return false;
                    }
                } catch (error) {
                    console.error("Error sending friend request:", error);
                    return false;
                }
            },
            async acceptFriendRequest(friendId: number) {
                try {
                    const uStore = useUserStore();
                    const config = useRuntimeConfig();
                    const response = await fetch(
                        `${config.public.API_BASE_URL}auth/friendship/${friendId}/accept_request/`,
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${uStore.accessToken}`
                            }
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        this.friends.push(data.accepted_user);
                        this.incomingRequests = this.incomingRequests.filter(
                            (friend) => friend.id !== friendId
                        );
                        return true;
                    } else {
                        console.error(
                            "Error accepting friend request:",
                            await response.text()
                        );
                        return false;
                    }
                } catch (error) {
                    console.error("Error accepting friend request:", error);
                    return false;
                }
            },
            async removeFriend(id: number) {
                const success: boolean = await this.deleteFriendship(id);
                if (success) {
                    this.friends = this.friends.filter(
                        (friend) => friend.id !== id
                    );
                }
                return success;
            },
            async removeIncomingRequest(id: number) {
                const success: boolean = await this.deleteFriendship(id);
                if (success) {
                    this.incomingRequests = this.incomingRequests.filter(
                        (friend) => friend.id !== id
                    );
                }
                return success;
            },
            async removeOutgoingRequest(id: number) {
                const success = await this.deleteFriendship(id);
                if (success) {
                    this.outgoingRequests = this.outgoingRequests.filter(
                        (friend) => friend.id !== id
                    );
                }
                return success;
            },
            async deleteFriendship(id: number) {
                try {
                    const config = useRuntimeConfig();
                    const uStore = useUserStore();
                    const response = await fetch(
                        `${config.public.API_BASE_URL}auth/friendship/${id}`,
                        {
                            method: "DELETE",
                            headers: {
                                Authorization: `Bearer ${uStore.accessToken}`
                            }
                        }
                    );
                    if (response.ok) {
                        return true;
                    } else {
                        console.error(
                            "Error removing friendship:",
                            await response.text()
                        );
                        return false;
                    }
                } catch (error) {
                    console.error("Error removing friendship:", error);
                    return false;
                }
            },
            async fetchLatestFriends() {
                try {
                    const uStore = useUserStore();
                    const config = useRuntimeConfig();
                    const response = await fetch(
                        `${config.public.API_BASE_URL}auth/friendship/my_friends/`,
                        {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${uStore.accessToken}`
                            }
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        this.setFriends(data.friends);
                    } else {
                        console.error(
                            "Error fetching latest friends:",
                            await response.text()
                        );
                    }
                } catch (error) {
                    console.error("Error fetching latest friends:", error);
                }
            },
            setFriends(friendships: FriendshipType[]) {
                const uStore = useUserStore();
                const userId = uStore.user?.id;
                this.friends = [];
                this.incomingRequests = [];
                this.outgoingRequests = [];

                friendships.forEach((friendship) => {
                    const friend =
                        friendship.sender.id === userId
                            ? friendship.receiver
                            : friendship.sender;
                    const formattedFriend = {
                        ...friend,
                        personalization: friend.personalization || null
                    };

                    if (friendship.status === "ACCEPTED") {
                        this.friends.push(formattedFriend);
                    } else if (friendship.status === "REQUESTED") {
                        if (friendship.sender.id === userId) {
                            this.outgoingRequests.push(formattedFriend);
                        } else {
                            this.incomingRequests.push(formattedFriend);
                        }
                    }
                });
            }
        }
    }
);
