import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import type { KeywordQuestionResponse } from "../interfaces/keywordsQuestions";
import { useUserStore } from "./userStore";
import type {
    Keyword,
    Question,
    Definition
} from "../interfaces/keywordsQuestions.ts";

interface State {
    known_keywords: Keyword[];
    unknown_keywords: Keyword[];
    known_questions: Question[];
    unknown_questions: Question[];
    definitions: Definition[];
}

export const useKeywordQuestionStore: StoreDefinition<
    "keywordQuestion",
    State
> = defineStore("keywordQuestion", {
    state: (): State => {
        return {
            known_keywords: [],
            unknown_keywords: [],
            known_questions: [],
            unknown_questions: [],
            definitions: []
        };
    },
    getters: {
        cleanedState: (state: State) => {
            const {
                known_keywords,
                unknown_keywords,
                known_questions,
                unknown_questions,
                definitions
            } = state || {};
            return {
                known_keywords,
                unknown_keywords,
                known_questions,
                unknown_questions,
                definitions
            };
        },
        unknownQuestions: (state: State) => {
            return state.unknown_questions;
        },
        knownQuestions: (state: State) => {
            return state.known_questions;
        }
    },
    actions: {
        setKQs(response: KeywordQuestionResponse) {
            this.known_keywords = response.known_keywords;
            this.unknown_keywords = response.unknown_keywords;
            this.known_questions = response.known_questions;
            this.unknown_questions = response.unknown_questions;
            this.definitions = response.definitions;
        },
        setQuestions(questions: any) {
            this.unknown_questions = questions;
        },
        async fetchLatestKQs() {
            try {
                const uStore = useUserStore();

                const response =
                    await uStore.userService?.fetchKeywordQuestions();
                if (response) {
                    this.setKQs(response);
                }
            } catch (err) {
                console.error(
                    "Error fetching user keywords and questions:",
                    err
                );
            }
        }
    }
});
