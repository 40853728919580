import { useUserStore } from "../store/userStore";
import { defineNuxtRouteMiddleware } from "nuxt/app";
import { useRouter } from "nuxt/app";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const protectedRouteNames = [
        "dashboard",
        "dashboard-index",
        "dashboard-user",
        "dashboard-userprefs"
    ];
    const router = useRouter();
    const uStore = useUserStore();

    if (protectedRouteNames.includes(to.name)) {
        if (!uStore.loggedIn) {
            router.push({ name: "index" });
        }
    }
});
